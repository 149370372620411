import { Injectable } from '@angular/core';
import {
  EnvironmentService,
  FeatureInputType,
  QuerySingleResultTypeBooleanType,
  SetFeaturesGQL,
} from '@clarilog/core';
import { camelCase } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkItem } from '../../components/form/work-item-form/work-item';
import { LicenseCoreService } from '@clarilog/core/services2/graphql/generated-types/services/license.service';
import { CorePolicyValidator } from '@clarilog/core/services2/authorization/authorization-policy-builder.service';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import { Router } from '@angular/router';
import { ModelFieldCompilerService } from '@clarilog/shared2';
import { MyOrganizationCoreService } from '@clarilog/core/services2/graphql/generated-types/services';

let testingFeature: WorkItem[] = [
  {
    title: 'globals/report',
    icon: 'fal fa-file-chart-pie',
    data: { route: 'reports', policies: ['report'] },
    name: 'reports',
  },
  // {
  //   title: 'entities/serviceDesk/_title/plural',
  //   icon: 'fal fa-headset',
  //   data: {
  //     route: 'service-desk',
  //     policies: ['help-desk-operator'],
  //   },
  //   name: 'service-desk',
  // },
];

let features: WorkItem[] = [
  {
    items: [
      {
        title: 'globals/dashboard',
        icon: 'fas fa-tachometer-alt-slow',
        data: { route: 'dashboard', policies: ['dashboard'] },
        name: 'dashboard',
      },
      {
        title: 'entities/asset/_title/plural',
        icon: 'fal fa-cube',
        data: {
          route: 'assets',
          policies: ['asset'],
          feature: 'feature-assets',
        },
        name: 'assets',
        globalSearch: true,
      },
      {
        title: 'globals/networkInventory',
        icon: 'fal fa-chart-network',
        data: {
          route: 'inventory',
          policies: ['scan-configuration'],
          policyOperator: 'or',
          feature: 'feature-inventories',
        },
        name: 'inventory',
      },
      {
        title: 'entities/teledistribution/_title/singular',
        icon: 'fal fa-boxes-packing',
        data: {
          route: 'teledistribution',
          policies: [
            'teledistribution.manage-packages',
            'teledistribution.manage-campaigns',
          ],
          policyOperator: 'or',
          feature: 'feature-teleDistributions',
        },
        name: 'teledistribution',
      },
      {
        title: 'entities/software/_title/plural',
        icon: 'fal fa-compact-disc',
        data: {
          route: 'softwares',
          policies: ['software'],
          feature: 'feature-software',
        },
        name: 'softwares',
        globalSearch: false,
      },
      {
        title: 'entities/contract/_title/plural',
        icon: 'fal fa-file-contract',
        data: {
          route: 'contracts',
          policies: ['contract'],
          feature: 'feature-contracts',
        },
        globalSearch: true,
        name: 'contracts',
      },
      {
        title: 'globals/financials',
        icon: 'fal fa-donate',
        data: {
          route: 'financials/budgets',
          policies: ['budget'],
          feature: 'feature-financials',
        },
        name: 'financials',
        globalSearch: false,
      },
      {
        title: 'entities/stock/_title/plural',
        icon: 'fal fa-box-full',
        data: {
          route: 'stocks',
          policies: [
            'in-stock',
            'out-stock',
            'stock',
            'correction-stock',
            'asset-model',
          ],
          policyOperator: 'or',
          feature: 'feature-stocks',
        },
        name: 'stocks',
      },
      {
        title: 'entities/loan/_title/plural',
        icon: 'fal fa-calendar-day',
        data: {
          route: 'loans',
          policies: ['loan'],
          feature: 'feature-loans',
        },
        name: 'loans',
        globalSearch: true,
      },
      {
        title: 'entities/user/_title/plural',
        icon: 'fal fa-users',
        data: { route: 'users', policies: ['user'] },
        name: 'users',
        globalSearch: true,
      },
      {
        title: 'entities/supplier/_title/plural',
        icon: 'fal fa-id-card-alt',
        data: {
          route: 'suppliers',
          policies: ['supplier'],
          feature: 'feature-suppliers',
        },
        name: 'suppliers',
        globalSearch: true,
      },
      {
        title: 'entities/task/_title/plural',
        icon: 'fal fa-tasks',
        data: {
          route: 'tasks',
          policies: ['task.all-tasks', 'task.my-tasks', 'task.my-team-tasks'],
          policyOperator: 'or',
          feature: 'feature-service-desk',
        },
        name: 'tasks',
      },
      {
        title: 'entities/ticket/_title/plural',
        icon: 'fal fa-clipboard-list',
        data: {
          route: 'tickets',
          policies: ['incident', 'request', 'ticket'],
          policyOperator: 'and',
          feature: 'feature-service-desk',
        },
        name: 'tickets-my',
        globalSearch: false,
      },
      {
        title: 'entities/incident/favorite',
        icon: 'fal fa-star',
        data: {
          route: 'favorite-tickets',
          policies: ['incident', 'request'],
          policyOperator: 'or',
          feature: 'feature-service-desk',
        },
        name: 'favorite-tickets',
      },
      {
        title: 'entities/incident/_title/plural',
        icon: 'fal fa-exclamation-triangle',
        data: {
          route: 'incidents',
          policies: [
            'incident.all-incidents',
            'incident.my-incidents',
            'incident.my-team-incidents',
          ],
          policyOperator: 'or',
          feature: 'feature-service-desk',
        },
        name: 'incidents',
        globalSearch: true,
      },
      {
        title: 'entities/request/_title/plural',
        icon: 'fal fa-user-headset',
        name: 'requests',
        data: {
          route: 'requests',
          policies: [
            'request.all-requests',
            'request.my-requests',
            'request.my-team-requests',
          ],
          policyOperator: 'or',
          feature: 'feature-service-desk',
        },
        globalSearch: true,
      },
      {
        title: 'entities/problem/_title/plural',
        icon: 'fal fa-seal-exclamation',
        name: 'problems',
        data: {
          route: 'problems',
          policies: [
            'problem.all-problems',
            'problem.my-problems',
            'problem.my-team-problems',
          ],
          policyOperator: 'or',
          feature: 'feature-service-desk',
        },
        globalSearch: true,
      },
      {
        title: 'entities/articleKnowledge/_title/plural',
        icon: 'fal fa-books',
        data: {
          route: 'article-knowledge',
          policies: ['knowledge'],
          feature: 'feature-service-desk',
          policyOperator: 'Or',
        },
        name: 'article-knowledge',
        globalSearch: false,
      },
      {
        title: 'entities/alert/_title/plural',
        icon: 'fal fa-bell-exclamation',
        data: {
          route: 'alerts',
          policies: ['alert'],
        },
        name: 'alerts',
      },
      {
        title: 'entities/notification/_title/plural',
        icon: 'fal fa-bell',
        data: {
          route: 'notifications',
          policies: ['my-notification', 'my-team-notification'],
          policyOperator: 'or',
        },
        name: 'notifications',
      },
      {
        title: 'globals/home-help-desk-user',
        icon: 'fal fa-home',
        data: {
          route: 'home',
          policies: ['help-desk-user.home-help-desk-user'],
          feature: 'feature-service-desk',
        },
        name: 'dashboard-user',
      },
      {
        title: 'entities/incident/_title/plural',
        icon: 'fal fa-file-medical-alt',
        data: {
          route: 'help-desk-incident',
          policies: ['incident-help-desk-user'],
          feature: 'feature-service-desk',
        },
        name: 'help-desk-incident',
        globalSearch: true,
      },
      {
        title: 'entities/request/_title/plural',
        icon: 'fal fa-user-headset',
        name: 'help-desk-request',
        data: {
          route: 'help-desk-request',
          policies: ['request-help-desk-user'],
          feature: 'feature-service-desk',
        },
        globalSearch: true,
      },
      {
        title: 'entities/articleKnowledge/_title/plural',
        icon: 'fal fa-book-open',
        data: {
          route: 'help-desk-knowledge',
          policies: ['help-desk-user.knowledge-help-desk-user'],
          feature: 'feature-service-desk',
        },
        name: 'help-desk-knowledge',
        globalSearch: true,
      },
      {
        title: 'entities/queryBuilder/_title/plural',
        icon: 'fal fa-database',
        data: {
          route: 'query-builders',
          policies: ['query-builder'],
        },
        name: 'query-builder',
      },
      {
        title: 'entities/asset/myAssets',
        icon: 'fal fa-cube',
        data: {
          route: 'help-desk-my-asset',
          policies: ['help-desk-user.my-asset-desk-user'],
          feature: 'feature-service-desk',
        },
        name: 'my-asset-user',
      },
      {
        title: 'entities/loan/myLoans',
        icon: 'fal fa-calendar-day',
        data: {
          route: 'help-desk-my-loan',
          policies: ['help-desk-user.my-loan-desk-user'],
          feature: 'feature-service-desk',
        },
        name: 'my-loan-user',
      },
    ],
  },
];

/**
 * Service relatif à la construction du menu
 *
 * @export
 * @class FeatureService
 */
@Injectable({ providedIn: 'root' })
export class CoreFeatureService {
  //fonction qui permet de switch entre help me et view op via un lien
  // si route == / on fait rien car changement manuel
  private isRouteHelpMe(url: string) {
    if (url === '/') {
      return;
    }

    let helpMeFeatures = features[0].items.filter(
      (x) =>
        x.data.policies.toString().startsWith('help-desk-user') ||
        x.data.policies.toString().startsWith('incident-help-desk-user') ||
        x.data.policies.toString().startsWith('request-help-desk-user'),
    );

    let currentHelpMeFeature = helpMeFeatures.filter((x) =>
      url.includes(x.data.route.toString()),
    );
    let goHelpMeView = currentHelpMeFeature.length > 0;

    let currentViewMode = localStorage.getItem('viewHelpMe');

    if (currentViewMode !== 'true' && goHelpMeView === true) {
      localStorage.setItem('viewHelpMe', 'true');
    }
    if (currentViewMode === 'true' && goHelpMeView === false) {
      localStorage.removeItem('viewHelpMe');
    }
  }
  constructor(
    private setFeaturesGQL: SetFeaturesGQL,
    private policyValidator: CorePolicyValidator,
    private licenseService: LicenseCoreService,
    private authorizationService: AuthorizationCoreService,
    private envService: EnvironmentService,
    private router: Router,
    private myOrganizationService: MyOrganizationCoreService,
  ) {
    this.isRouteHelpMe(router.url);
    if (
      policyValidator.validate('/^help-desk-user') &&
      (this.authorizationService.user.hasHelpDeskOperator() ||
        this.authorizationService.user.hasManager())
    ) {
      let viewHelpMe = localStorage.getItem('viewHelpMe');
      if (viewHelpMe == 'true') {
        features[0].items = features[0].items.filter(
          (x) =>
            x.data.policies.toString().startsWith('help-desk-user') ||
            x.data.policies.toString().startsWith('incident-help-desk-user') ||
            x.data.policies.toString().startsWith('request-help-desk-user'),
        );
      } else {
        features[0].items = features[0].items.filter(
          (x) =>
            !(
              x.data.policies.toString().startsWith('help-desk-user') ||
              x.data.policies
                .toString()
                .startsWith('incident-help-desk-user') ||
              x.data.policies.toString().startsWith('request-help-desk-user')
            ),
        );
        if (this.envService.isProduction === false) {
          if (
            testingFeature.some((r) => features[0].items.includes(r)) === false
          ) {
            features[0].items.push(...testingFeature);
          }
        }
      }
    }
  }

  async hasAccessToOrganizationLicenseAsync() {
    let tenantId = this.authorizationService.user.getTenantId();

    let hasAccess = (
      await this.myOrganizationService
        .hasAccessToOrganizationLicense(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          tenantId,
        )
        .toPromise()
    ).data;

    return hasAccess;
  }

  /** Permet de vérifier si le SD est actif */
  async hasServiceDeskAsync() {
    let sd = localStorage.getItem('serviceDesk');

    if (sd != undefined) {
      return sd == 'true' ? true : false;
    } else {
      let feature = 'featureServiceDesk';
      let featureServiceDesk = this.getLicenceFeature(feature);
      featureServiceDesk.then((response) => {
        localStorage.setItem('serviceDesk', response.toString());
      });

      return featureServiceDesk;
    }
  }

  /** Licence Mailbox observer */
  async hasMOB() {
    let featureMailBoxObserver = await this.getLicenceFeature(
      'featureMailBoxObserver',
    );

    return featureMailBoxObserver;
  }

  /**Vérifie que la gestion des stocks est activé */
  async hasStockAvailableLicence(): Promise<boolean> {
    let feature = 'featureStocks';
    return this.getLicenceFeature(feature);
  }

  /**Récupere toute les features  */
  async getAllFeature() {
    let license = await this.licenseService
      .get(
        LicenseCoreService.fields(),
        this.authorizationService.user.getClaim('tenantId'),
      )
      .toPromise();
    return license?.data?.features;
  }

  checkFeature(feature: string, features) {
    let date = features[feature + 'ExpireAt'];
    let value =
      features[feature] && (date == undefined || new Date(date) > new Date());
    return value;
  }

  /**Verifie que le 'service/métiers' est activé  */
  private async getLicenceFeature(feature: string): Promise<boolean> {
    let license = await this.licenseService
      .get(
        LicenseCoreService.fields(),
        this.authorizationService.user.getClaim('tenantId'),
      )
      .toPromise();
    let date = license.data.features[feature + 'ExpireAt'];
    let value =
      license.data.features[feature] &&
      (date == undefined || new Date(date) > new Date());
    return value;
  }

  /** Récupère les éléments du menu. */
  async features(): Promise<WorkItem[]> {
    // Lorsqu'on est sur le main --> this.licenseService.get() génère des bugs

    if (
      window.location.toString().includes('https://main.') ||
      window.location.toString().includes('https://www.') ||
      window.location.toString().includes('http://main.') ||
      window.location.toString().includes('http://www.')
    ) {
      // PAs de gestion de feature / licence si on est sur le root applicatif
      let featuresDefault: WorkItem[] = [];
      return featuresDefault;
    }
    let license = await this.licenseService
      .get(
        LicenseCoreService.fields(),
        this.authorizationService.user.getClaim('tenantId'),
      )
      .toPromise();

    return features.map((feature) => {
      let items = [];
      feature.items.map((item) => {
        let feature = item.data.feature;
        item.enabled = true;
        if (feature != undefined) {
          let date = license.data.features[camelCase(feature) + 'ExpireAt'];
          item.enabled =
            license.data.features[camelCase(feature)] &&
            (date == undefined || new Date(date) > new Date());
        }
        if (
          (item.enabled == undefined || item.enabled === true) &&
          this.policyValidator.validate(
            item.data.policies,
            item.data.policyOperator,
          )
        ) {
          items.push(item);
        }
      });
      feature.items = items;
      return feature;
    });
  }

  /**
   *  Permet de sauvegarder l'ordre des features du menu principal
   *
   * @param {FeatureInputType[]} features
   * @returns {Observable<boolean>}
   * @memberof FeatureService
   */
  saveFeatureOrder(
    features: FeatureInputType[],
  ): Observable<QuerySingleResultTypeBooleanType> {
    let result = this.setFeaturesGQL.mutate({ features: features });
    return result.pipe(map((result) => result.data.users.setFeatures));
  }
}
