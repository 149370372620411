export * from './access-point.service-base'
export * from './account.service-base'
export * from './activity.service-base'
export * from './affected-rule.service-base'
export * from './agent-configuration.service-base'
export * from './agent-history.service-base'
export * from './agent-token.service-base'
export * from './alarm-mail-task.service-base'
export * from './alarm.service-base'
export * from './alert.service-base'
export * from './alert-task.service-base'
export * from './analyse-scan-by-azure-ad-task.service-base'
export * from './anti-spyware-product.service-base'
export * from './anti-virus-product.service-base'
export * from './application-azure-ad.service-base'
export * from './app-log.service-base'
export * from './appointment.service-base'
export * from './article-knowledge-category.service-base'
export * from './article-knowledge-note.service-base'
export * from './article-knowledge.service-base'
export * from './asset-account.service-base'
export * from './asset-acquisition-mode.service-base'
export * from './asset-acquisition-state.service-base'
export * from './asset-affected-rule.service-base'
export * from './asset-category.service-base'
export * from './asset-category-rule.service-base'
export * from './asset-contract.service-base'
export * from './asset-cost-center.service-base'
export * from './asset-loan.service-base'
export * from './asset-model.service-base'
export * from './asset-model-stock-entry.service-base'
export * from './asset-property.service-base'
export * from './asset-property-tracking.service-base'
export * from './asset-provisional-commissioning.service-base'
export * from './asset.service-base'
export * from './asset-status.service-base'
export * from './asset-type-accounting.service-base'
export * from './asset-type-staffing.service-base'
export * from './async-task.service-base'
export * from './auto-run.service-base'
export * from './axel-datum.service-base'
export * from './base-board.service-base'
export * from './battery.service-base'
export * from './bio.service-base'
export * from './boot-configuration.service-base'
export * from './budget-category.service-base'
export * from './budget.service-base'
export * from './business-time.service-base'
export * from './calendar-connector.service-base'
export * from './campaign-history.service-base'
export * from './campaign.service-base'
export * from './c-d-rom-drive.service-base'
export * from './channel.service-base'
export * from './chat-bot.service-base'
export * from './check-file-task.service-base'
export * from './check-size-file-task.service-base'
export * from './clarilog-server.service-base'
export * from './codec-file.service-base'
export * from './command.service-base'
export * from './commentary-model.service-base'
export * from './computer-system-product.service-base'
export * from './computer-system.service-base'
export * from './concerned-project.service-base'
export * from './consumable-movement.service-base'
export * from './contract-category.service-base'
export * from './contract-license-type.service-base'
export * from './contract.service-base'
export * from './copy-count.service-base'
export * from './correction-stock-movement.service-base'
export * from './cost-center.service-base'
export * from './cron-job-hourly-task.service-base'
export * from './custom-field.service-base'
export * from './dashboard.service-base'
export * from './data-file.service-base'
export * from './day-off.service-base'
export * from './delete-file-task.service-base'
export * from './desktop.service-base'
export * from './disk-drive.service-base'
export * from './disk-ram-information.service-base'
export * from './domain.service-base'
export * from './dynamic-property-field.service-base'
export * from './dynamic-property-group.service-base'
export * from './dynamic-property-list.service-base'
export * from './email-connector.service-base'
export * from './email-connector-system-log.service-base'
export * from './email-connector-system.service-base'
export * from './email-credential.service-base'
export * from './email-prototype-log.service-base'
export * from './email-prototype.service-base'
export * from './encryptable-volume.service-base'
export * from './entry-table.service-base'
export * from './environment-variable.service-base'
export * from './esx-credential.service-base'
export * from './exchange-connector.service-base'
export * from './fax-count.service-base'
export * from './feedback.service-base'
export * from './field-mapping.service-base'
export * from './firewall-product.service-base'
export * from './font-info-action.service-base'
export * from './form-designer.service-base'
export * from './group-azure-ad.service-base'
export * from './group.service-base'
export * from './html-fields-setting.service-base'
export * from './iamt-credential.service-base'
export * from './ii.service-base'
export * from './impact.service-base'
export * from './import-audit-task.service-base'
export * from './incident-model.service-base'
export * from './incident.service-base'
export * from './infrared-device.service-base'
export * from './inherit-location-charter.service-base'
export * from './in-stock-movement.service-base'
export * from './internet-explorer.service-base'
export * from './intervention-model.service-base'
export * from './intervention.service-base'
export * from './inventory-history.service-base'
export * from './keyboard.service-base'
export * from './label-format.service-base'
export * from './label-template.service-base'
export * from './layout-model.service-base'
export * from './ldap-analysis.service-base'
export * from './ldap-history.service-base'
export * from './ldap-organizational-unit.service-base'
export * from './ldap-property.service-base'
export * from './license-key.service-base'
export * from './life-cycle-destination.service-base'
export * from './life-cycle-reason.service-base'
export * from './life-cycle-ticket.service-base'
export * from './link-asset.service-base'
export * from './list-designer.service-base'
export * from './loan-appointment.service-base'
export * from './loan.service-base'
export * from './loan-status.service-base'
export * from './location-category.service-base'
export * from './location-charter.service-base'
export * from './location.service-base'
export * from './logical-disk-history.service-base'
export * from './logical-disk.service-base'
export * from './logon-history.service-base'
export * from './logon-session.service-base'
export * from './mailbox-observer-log.service-base'
export * from './mailbox-observer-task.service-base'
export * from './mail-slurp-black-list.service-base'
export * from './mail-slurp-webhook-task.service-base'
export * from './matrix-priority.service-base'
export * from './message-model.service-base'
export * from './message.service-base'
export * from './model-manufacturer.service-base'
export * from './model-rules-designer.service-base'
export * from './monitor.service-base'
export * from './naming-convention-history.service-base'
export * from './naming-convention.service-base'
export * from './network-adapter.service-base'
export * from './network-credential.service-base'
export * from './network-drive.service-base'
export * from './news.service-base'
export * from './note.service-base'
export * from './notification.service-base'
export * from './nt-log-event.service-base'
export * from './object-class-mapping.service-base'
export * from './odbc-driver.service-base'
export * from './one-event-log.service-base'
export * from './one-setting.service-base'
export * from './operating-system.service-base'
export * from './operator-team.service-base'
export * from './optional-feature.service-base'
export * from './order-bill.service-base'
export * from './order.service-base'
export * from './organizational-unit-category.service-base'
export * from './organizational-unit.service-base'
export * from './organization.service-base'
export * from './origin.service-base'
export * from './os-recovery-configuration.service-base'
export * from './out-stock-movement.service-base'
export * from './package.service-base'
export * from './page-count.service-base'
export * from './page-file.service-base'
export * from './parallel-port.service-base'
export * from './pat.service-base'
export * from './pcmcia-controller.service-base'
export * from './physical-memory-array.service-base'
export * from './physical-memory.service-base'
export * from './pnp-entity-base.service-base'
export * from './pnp-entity.service-base'
export * from './pnp-signed-driver.service-base'
export * from './pnp-vendor.service-base'
export * from './pointing-device.service-base'
export * from './population.service-base'
export * from './portable-battery.service-base'
export * from './port-connector.service-base'
export * from './port-open.service-base'
export * from './pots-modem.service-base'
export * from './printer-consumable.service-base'
export * from './printer.service-base'
export * from './priority.service-base'
export * from './privilege.service-base'
export * from './problem.service-base'
export * from './processor.service-base'
export * from './process.service-base'
export * from './program.service-base'
export * from './query-builder-appointment.service-base'
export * from './query-builder-appointment-task.service-base'
export * from './query-builder.service-base'
export * from './quick-fix-engineering.service-base'
export * from './registry.service-base'
export * from './report-cach.service-base'
export * from './report.service-base'
export * from './request.service-base'
export * from './resolution-category.service-base'
export * from './resolution-model.service-base'
export * from './result-scan-azure-ad.service-base'
export * from './role-account.service-base'
export * from './role.service-base'
export * from './satisfaction-setting.service-base'
export * from './scan-by-address-ip-range.service-base'
export * from './scan-by-azure-ad-appointment.service-base'
export * from './scan-by-azure-ad-appointment-task.service-base'
export * from './scan-by-azure-ad.service-base'
export * from './scan-by-azure-ad-task.service-base'
export * from './scan-by-host.service-base'
export * from './scan-by-ldap.service-base'
export * from './scan-by-local-agent.service-base'
export * from './scan-by-workgroup.service-base'
export * from './scan-configuration-appointment.service-base'
export * from './scan-configuration.service-base'
export * from './scan-data-file.service-base'
export * from './scan-exclude.service-base'
export * from './scan-import-option.service-base'
export * from './scan-method-profil.service-base'
export * from './scan-method.service-base'
export * from './scanner-count.service-base'
export * from './scan-registry-key.service-base'
export * from './scan-registry.service-base'
export * from './schedule-task-error.service-base'
export * from './schedule-task.service-base'
export * from './scsi-controller.service-base'
export * from './search-warranty-task.service-base'
export * from './security-group.service-base'
export * from './security-group-rule.service-base'
export * from './serial-number.service-base'
export * from './serial-port.service-base'
export * from './service-level-agreement.service-base'
export * from './service-offer.service-base'
export * from './sftp-connector-history.service-base'
export * from './sftp-connector.service-base'
export * from './share.service-base'
export * from './smtp-connector-system.service-base'
export * from './snmp-credential.service-base'
export * from './snmp-map-information-kb.service-base'
export * from './snmp-map-information.service-base'
export * from './snmp-summary.service-base'
export * from './snmp-vendor.service-base'
export * from './software-attribute-type.service-base'
export * from './software-connection-mode.service-base'
export * from './software-group.service-base'
export * from './software-hosted-component.service-base'
export * from './software-hosted-link.service-base'
export * from './software-package.service-base'
export * from './software-property.service-base'
export * from './software-referent-link.service-base'
export * from './software-referent-type.service-base'
export * from './software.service-base'
export * from './sound-device.service-base'
export * from './sql-server.service-base'
export * from './ssh-credential.service-base'
export * from './statistic-task.service-base'
export * from './status-workflow.service-base'
export * from './stock-in-reason.service-base'
export * from './stock-out-reason.service-base'
export * from './stock-transfer-reason.service-base'
export * from './stock-transfer.service-base'
export * from './substitute.service-base'
export * from './supplier.service-base'
export * from './system-account.service-base'
export * from './system-enclosure.service-base'
export * from './system-slot.service-base'
export * from './tape-drive.service-base'
export * from './task-sequence.service-base'
export * from './task-status.service-base'
export * from './task-status-workflow.service-base'
export * from './template.service-base'
export * from './terms-contract.service-base'
export * from './ticket-affected-rule.service-base'
export * from './ticket-appointment.service-base'
export * from './ticket-backlog.service-base'
export * from './ticket-category.service-base'
export * from './ticket-daily-backlog.service-base'
export * from './ticket-duration-task.service-base'
export * from './ticket-email.service-base'
export * from './ticket-email-task.service-base'
export * from './ticket-monthly-backlog.service-base'
export * from './ticket.service-base'
export * from './ticket-statistic.service-base'
export * from './ticket-status-reason.service-base'
export * from './ticket-status.service-base'
export * from './ticket-tag.service-base'
export * from './ticket-tag-task.service-base'
export * from './ticket-task-model.service-base'
export * from './ticket-task.service-base'
export * from './ticket-weekly-backlog.service-base'
export * from './ticket-yearly-backlog.service-base'
export * from './tracking-entity.service-base'
export * from './tracking.service-base'
export * from './uptime-calendar.service-base'
export * from './urgency.service-base'
export * from './user-account-control.service-base'
export * from './user-account.service-base'
export * from './user-affected-rule.service-base'
export * from './user-privilege.service-base'
export * from './user-profile.service-base'
export * from './user-role.service-base'
export * from './user.service-base'
export * from './verifalia-email-check-metric.service-base'
export * from './verifalia-email-check.service-base'
export * from './verifalia-task.service-base'
export * from './video-controller.service-base'
export * from './virtual-machine.service-base'
export * from './vlan.service-base'
export * from './wall-socket.service-base'
export * from './warning-customer.service-base'
export * from './warning-message.service-base'
export * from './warning-view-customer.service-base'
export * from './warranty-link-vendor.service-base'
export * from './windows-firewall.service-base'
export * from './windows-service.service-base'
export * from './windows-update.service-base'
export * from './wmi-credential.service-base'
export * from './work-application.service-base'
export * from './workflow-action.service-base'
export * from './workflow-delete.service-base'
export * from './workflow-http-request.service-base'
export * from './workflow-interval-task.service-base'
export * from './workflow-intervention.service-base'
export * from './workflow-invitation.service-base'
export * from './workflow-invitation-task.service-base'
export * from './workflow-link-item.service-base'
export * from './workflow-log.service-base'
export * from './workflow-mail.service-base'
export * from './workflow-mail-task.service-base'
export * from './workflow-managed.service-base'
export * from './workflow-notification-team.service-base'
export * from './workflow-property.service-base'
export * from './workflow-revocation.service-base'
export * from './workflow-revocation-without-email.service-base'
export * from './workflow.service-base'
export * from './workflow-schedule-task.service-base'
export * from './workflow-ticket-task.service-base'
export * from './work-time.service-base'
export * from './my-organization.service-base'
export * from './agent.service-base'
export * from './common.service-base'
export * from './license.service-base'
export * from './contact.service-base'
export * from './clarilog-local-agent.service-base'
export * from './file-manager.service-base'
