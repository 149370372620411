import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Injector,
  Input,
  isDevMode,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SecurityContext,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ActivatedRoute,
  Route,
  Router,
  RouterEvent,
  RouterLink,
} from '@angular/router';
import { StateManagementService } from '@clarilog/core';
import {
  ModelDataSourceContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { DxTreeViewComponent } from 'devextreme-angular';
import { dxTreeViewNode } from 'devextreme/ui/tree_view';
import { OptionsBase } from '../../list/list/list.component-base';
import { WorkItemsHelper } from '../../list/work-items/work-items-helpers';

export class EventArgs<T> {
  component: T;
  event: Event | RouterEvent;
}
export class CancelableEventArgs<T> extends EventArgs<T> {
  cancel: boolean = false;
}
export class ChangesEventArgs<T> extends EventArgs<T> {
  changes: SimpleChanges;
}
export type NavMenuItemMode = 'icon' | 'full';

export class FilterArgs {
  filters: string[] | string[][];
}

@Component({
  selector: 'clc-nav-menu-item',
  templateUrl: './nav-menu-item.component.html',
  styleUrls: ['./nav-menu-item.component.scss'],
  providers: [RouterLink],
})
export class CoreNavMenuItemComponent
  extends OptionsBase
  implements OnInit, OnChanges
{
  @Input() routerLink: string;

  private _handleEnter: NodeJS.Timeout;
  /** Obtient ou définit le mode. */
  @Input() mode: NavMenuItemMode = 'full';
  /** Obtient ou définit un état montrant une erreur. */
  @Input() isValid: boolean = true;
  /** Obtient ou définit une valeur indiquant si le bouton est actif. */

  _active: boolean = false;

  get active(): boolean {
    return this._active;
  }

  @Input('active')
  set active(value: boolean) {
    if (value === false) {
      this._state.deleteState('nav-menu-item/' + this.text);
      let currentText = this._state.getState('nav');
      if (this.text !== currentText) {
        this._state.deleteState('nav-menu-item/' + currentText);
      } else {
        this._state.deleteState('nav-menu-item/' + this.text);
      }
    }
    this._active = value;
  }
  /** Obtient ou définit la valeur du text affiché. */
  @Input() text: string = undefined;
  /** Obtient ou définit la fonction nombre d'élément à affiché. */
  @Input() countElementFn;
  /** Obtient ou définit le nombre d'élément à affiché. */
  @Input() countElement: number = undefined;
  /** Obtient ou définit l'icône. */
  @Input() icon: string = undefined;
  /** Obtient ou définit le champ à afficher. */
  @Input() displayExpr: string = undefined;
  /** Obtient ou définit le champ de filtrage. */
  @Input() filterExpr: string = undefined;
  /** Obtient ou définit le modele state */
  @Input() modelState: ModelState;

  @Input() refreshView: Array<string> = undefined;
  @Input() enabledSource: ModelDataSourceContext = undefined;
  /** Se déclenche au clique sur l'élément. */
  @Output() onClick: EventEmitter<EventArgs<CoreNavMenuItemComponent>> =
    new EventEmitter<EventArgs<CoreNavMenuItemComponent>>();
  @Output() onMouseEnter: EventEmitter<void> = new EventEmitter<void>();
  @Output() onMouseDown: EventEmitter<EventArgs<CoreNavMenuItemComponent>> =
    new EventEmitter<EventArgs<CoreNavMenuItemComponent>>();
  /** Se déclenche si un ou des éléments du treeview sont sélectionnés. */
  @Output() onItemSelectionChanged: EventEmitter<FilterArgs> =
    new EventEmitter<FilterArgs>();

  /**Envoie les éelements sélectionnés dans le filtre (gauche) au contexte d'exécution*/
  @Output() onItemSelected: EventEmitter<{ name: string; data: string[] }> =
    new EventEmitter<{ name: string; data: string[] }>();

  /** Obtient ou définit le treeview. */
  @ViewChild(DxTreeViewComponent, { static: false })
  treeView: DxTreeViewComponent;
  /** Obtient ou définit une valuer indiquant son état d'activation. */
  @Input() enabled: boolean = true;
  /** Obtient ou définit la visibilité du composent. */
  @Input() visibled: boolean = true;
  /** Obtient ou définit si le composant a une notification badge. */
  @Input() isBadge: boolean = false;
  /** Obtient ou définit le message d'aide. */
  @Input() hint: string;
  /** Evite un loop dans le ContentReady. */
  isContentReady: boolean = false;
  /** Paramètres de filtre. */
  stateFilters: any;
  /** Evite un loop dans le ContentReady. */
  isNodeExpended: boolean = false;

  operation: boolean = true;
  /** Sélectionne le bouton actif. */
  selectedItemKeys: any[] = [];
  @Input() filterOperation: string;

  @Input() filterLive: boolean = false;
  @Input() filterContext: string = undefined;

  @Input() inContext: boolean = false;

  @Input() haveFilter: boolean = false;
  @Input() parameterType: any;

  @Input() canAccumulateFitler: boolean = true;
  @Input() deniedCumulateFilter: boolean = true;
  @Input() canByPassItemSelectionChanged: boolean = false;

  cumulateFilterList: Array<string> = [];
  notNecessary: boolean = false;

  constructor(
    public elementRef: ElementRef,
    private cd: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private _router: Router,
    private _state: StateManagementService,
    private domSanitize: DomSanitizer,
    private serviceInjector: Injector,
    private renderer: Renderer2,
    private workItemHelper: WorkItemsHelper,
  ) {
    super();
  }

  contentReady(e) {
    if (
      this.stateFilters != undefined &&
      this.isContentReady === false &&
      this.isNodeExpended === false
    ) {
      this.isContentReady = true;
      this.isNodeExpended = true;
      let keys = [];
      this.cd.detectChanges();
      this.treeView.instance.beginUpdate();
      this.stateFilters.ids.forEach((key) => {
        this.treeView.instance.selectItem(key);
        keys.push(key);
        keys = keys.concat(this.getAllChildren(key));
      });
      keys.forEach((key) => {
        this.treeView.instance.selectItem(key);
      });
      this.treeView.instance.endUpdate();

      this.isContentReady = false;
      this.itemSelectionChanged({ component: this.treeView.instance });
      this.treeView.instance.beginUpdate();
      keys.forEach((key) => {
        this.treeView.instance.expandItem(key);
      });
      this.treeView.instance.endUpdate();
      this.isNodeExpended = false;
    } else {
      // TODO ne fait pas le reload correctement sur le retour
      // let data = this.processSelected(
      //   e.component.getSelectedNodesKeys(),
      //   e.component.getDataSource().items(),
      // );
      // if (data != undefined && data.length > 0) {
      //   this.onItemSelectionChanged.emit(this.filters(data));
      // }
      //- Probleme avec le backurl il faut conserver le filtre
      //  e.component.unselectAll();
      //  this.refresh(e);
    }

    if (
      this.refreshView != undefined &&
      this.treeView &&
      this.source != undefined
    ) {
      //- Décalage entre le ngOnChanges et le contentReady

      setTimeout(async () => {
        if (
          this.treeView?.instance != undefined &&
          this.treeView.instance.getSelectedNodeKeys().length == 0 &&
          this.source != undefined
        ) {
          this.refreshView?.map((element) => {
            this.treeView.instance.selectItem(element);
            this.treeView.instance.expandItem(element);
          });
          this.canByPassItemSelectionChanged = false;
        }
      }, 950);
    }
  }

  initialized(e) {
    this.applySortOrder(true);
  }

  /** Récupère tous les noeuds enfants d'un noeud. */
  getAllChildren(key) {
    let items = this.treeView.instance.getDataSource().items();
    let results = [];
    items.forEach((item) => {
      if (item[this.parentIdExpr] === key) {
        results.push(item[this.keyExpr]);
        results = results.concat(this.getAllChildren(item[this.keyExpr]));
      }
    });
    return results;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.parameterType != undefined && this.parameterType != null) {
      this.haveFilter = this.haveFilter = this.filterIndicator(
        this.parameterType,
      );
    }

    if (this.treeView != undefined) {
    } else {
      this.cd.detectChanges();
    }
    if (this.treeView != undefined) {
    }
    if (this.treeView != undefined && changes.refreshView != undefined) {
      this.treeView.instance.repaint();
      this.treeView.instance.unselectAll();
    }
    if (
      changes.active != undefined &&
      changes.refreshView === undefined &&
      this.treeView != undefined
    ) {
      this.treeView.instance.unselectAll();
      this.treeView.instance.collapseAll();
    }

    if (
      changes.source != undefined &&
      changes.source.currentValue != undefined
    ) {
      // TODO : Pour l'instant forcé sur le display filed à voir si besoin de sortir en paramètre.

      this.applySortOrder();
    }
  }

  updateNumber(value: number) {
    if (value != 0) {
      this.countElement = value;
    }
  }
  change() {
    this.cd.detectChanges();
  }
  /** @inheritdoc */
  ngOnInit(): void {
    this.getCumulFilterList();
    if (this.countElementFn?.subscribe != undefined) {
      // Méthode
      this.countElementFn.subscribe((res) => {
        this.updateNumber(res.totalCount);
      });
    }
    if (this.hint == undefined || this.hint == null) {
      this.hint = this.text;
    }

    if (this.hint == undefined || this.hint == null) {
      this.hint = '';
    }

    if (this.hint.indexOf('/>') != -1 || this.hint.indexOf('</') != -1) {
      this.hint = this.hint.replace(/(<([^>]+)>)/gi, '');
      this.hint = this.domSanitize.sanitize(SecurityContext.HTML, this.hint);
      let contentHTML = new DOMParser().parseFromString(this.hint, 'text/html')
        .documentElement.textContent;

      this.hint = contentHTML.trim();
      if (this.hint.trim() == '') {
        this.hint = this.text;
      }
    }

    let state = { ...window.history.state };
    let savedState = this._state.getState('nav-menu-item/' + this.text);
    if (state.filters != undefined) {
      savedState = state.filters.ids;
    }

    if (savedState != undefined) {
      state.filters = {
        ids: savedState,
      };
    }

    if (state != undefined) {
      if ('filters' in state) {
        this.stateFilters = state.filters;
      }
    }

    // if (this._state.getState('nav-menu-item/' + this.text) != undefined) {
    //   if (this._state.getState('nav-menu-item/' + this.text) === true) {
    //     this.click(null);
    //   }
    // }
    // TODO A revoir
    // let selectedNodes = this.loadSelectedTreeList();
    // if (selectedNodes != undefined) {
    //   this.stateFilters = { ids: selectedNodes.ids };
    //   this.recursive = selectedNodes.recursive;
    // }
    // if (this.recursive === true) {
    //   this.selectedItemKeys.push('recursive');
    // }
    // console.log('init', { recursive: this.recursive, ids: this.stateFilters != undefined ? this.stateFilters.ids : undefined })

    if (
      this.parameterType != undefined &&
      this.parameterType != null &&
      this.deniedCumulateFilter == false
    ) {
      this.parameterType = this.workItemHelper.getParameterTypeName(
        this.parameterType,
      );

      this.haveFilter = this.filterIndicator(this.parameterType);

      if (this.haveFilter) {
        let getFilterValue = this.workItemHelper.expandFilter(
          this.parameterType,
        );
        if (getFilterValue?.length > 0) {
          this.refreshView = this.workItemHelper.getNode(getFilterValue);
        }
      }
    }
  }

  /** Permet de hoster le style display. */
  @HostBinding('style.display')
  get display(): string {
    return this.visibled ? 'block' : 'none';
  }
  @HostBinding('class.cl-active')
  get activeClass(): boolean {
    return this.active;
  }
  /** Se déclenche lorsque la souris sort du composent. */
  @HostListener('mouseenter')
  mouseEnter() {
    this._handleEnter = setTimeout(() => {
      this.onMouseEnter.emit();
    }, 300);
  }
  @HostListener('mouseleave')
  onMouseLeave() {
    clearTimeout(this._handleEnter);
  }
  changeRecursive(e) {
    this.recursive = !this.recursive;
    this.cd.detectChanges();
    // TODO A revoir
    // this.saveSelectedTreeList(e);
    this.refresh(e);
  }
  /** Rafraîchi. */
  refresh(e) {
    let data = this.processSelected(
      e.component.getSelectedNodeKeys(),
      e.component.getDataSource().items(),
    );

    this.onItemSelectionChanged.emit(this.filters(data));
  }
  // TODO A revoir
  // private loadSelectedTreeList() {
  //   let expandedKeys = localStorage.getItem(
  //     'selectedKeys' + (this._route.component as any).name,
  //   );
  //   console.log('loadSelectedTreeList', expandedKeys);
  //   if (expandedKeys != undefined) {
  //     return JSON.parse(expandedKeys);
  //   }

  //   return undefined
  // }
  // private saveSelectedTreeList(e) {
  //   if (this._route.component != undefined) {
  //     localStorage.setItem(
  //       'selectedKeys' + (this._route.component as any).name,
  //       JSON.stringify({ recursive: this.recursive, ids: e.component.getSelectedNodesKeys() }),
  //     );
  //     console.log('saveSelectedTreeList', { recursive: this.recursive, ids: e.component.getSelectedNodesKeys() });
  //   }
  // }
  // private clearSelectedTreeList() {
  //   if (this._route.component != undefined) {
  //     localStorage.removeItem(
  //       'selectedKeys' + (this._route.component as any).name
  //     );
  //     let expandedKeys = localStorage.getItem(
  //       'selectedKeys' + (this._route.component as any).name,
  //     );
  //     console.log('clearSelectedTreeList', expandedKeys);
  //   }
  // }
  /** Lorsque des éléments sont sélectionnées. */
  itemSelectionChanged(e: any) {
    // TODO A revoir
    // this.saveSelectedTreeList(e);
    this.refreshView = undefined;
    if (this.isContentReady === true) return;

    let data = this.processSelected(
      e.component.getSelectedNodeKeys(),
      e.component.getDataSource().items(),
    );

    this._state.setState(
      'nav-menu-item/' + this.text,
      data.map((node) => node[this.keyExpr]),
    );

    let filterContextValue = [];
    data.map((element) => {
      filterContextValue.push(element.id);
    });

    let filterData = data;

    if (this.deniedCumulateFilter == false) {
      let searchValue = e.component.option('searchValue');
      if (searchValue != undefined && searchValue != null) {
        let parentNodeSelectedId = e?.itemData?.parentId;
        if (parentNodeSelectedId != undefined && parentNodeSelectedId != null) {
          let unselectParentIds = this.unSelectedNodeParentId(e?.node?.parent, [
            e.node.parent.key,
          ]);
          if (unselectParentIds.length > 0) {
            filterData = data.filter(
              (element) => !unselectParentIds.includes(element['id']),
            );
          }
        }
      }
    }

    if (this.filterContext != undefined) {
      this.onItemSelected.emit({
        name: this.filterContext,
        data: filterContextValue,
      });

      filterData = [];
    }

    let permissionToCumulateFilter = this.workItemHelper.canAccumulateFitler(
      this.canAccumulateFitler,
    );
    if (this.deniedCumulateFilter == false) {
      if (filterData.length > 0 && permissionToCumulateFilter) {
        this.haveFilter = true;
        this.getCumulFilterList();
        if (!this.cumulateFilterList.includes(this.parameterType)) {
          this.cumulateFilterList.push(this.parameterType);
          sessionStorage.setItem(
            'cumulFilter',
            JSON.stringify(this.cumulateFilterList),
          );
        }
      } else {
        this.haveFilter = false;
        this.removeFilterName();
      }

      if (this.canByPassItemSelectionChanged) {
        return;
      }
    }
    this.onItemSelectionChanged.emit(this.filters(filterData));
  }

  private processSelected(selectedKeys: [], data: []) {
    let results = [];
    for (let key of selectedKeys) {
      let search = data.filter((item) => item[this.keyExpr] === key);
      if (search.length > 0) {
        results.push(search[0]);
      }
    }

    return results;
  }
  /** Permet de générer le filtre compatible DeveXtreme. */
  private filters(data: any[]): any {
    let filters: any = {};
    if (this.inContext != undefined && this.inContext === true) {
      //console.error('nav-menu-item filters not implem');
      // this._modelCompilerService.context.params.set('ids', () => data.map(node => node[this.keyExpr]));
    } else {
      //need to add  name.toto.tutu
      if (data.length > 0) {
        if (
          this.filterOperation === 'notcontains' ||
          this.filterOperation === 'contains'
        ) {
          filters.filters = [
            this.filterExpr,
            this.filterOperation,
            data.map((m) => m[this.keyExpr]),
          ];
        } else if (this.filterOperation === 'stringcontains') {
          filters.filters = [
            this.filterExpr,
            'contains',
            data.map((m) => m[this.keyExpr])[0].toString(),
          ];
        } else {
          filters.filters = data
            .map((node) => [
              this.filterExpr,
              this.filterOperation == undefined ? '=' : this.filterOperation,
              node[this.keyExpr],
            ])
            .reduce(
              (previousValue, currentValue, currentIndex) => {
                if (currentIndex === 0) {
                  return [currentValue];
                } else {
                  return previousValue.concat(
                    [<any>'or'].concat([currentValue]),
                  );
                }
              },
              ['or'],
            );
          if (filters.filters.length === 1) {
            filters.filters = filters.filters[0];
          }
        }
      }
    }

    return filters;
  }

  // Fonction pour vérifier si une route existe en comparant les segments du tableau
  isRouteExists(arrayUrl: string[]): boolean {
    const routes: Route[] = this._router.config;
    const result = routes[0].children[0].children.filter(
      (x) => x.path == arrayUrl[arrayUrl.length - 1],
    );
    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  @HostListener('mouseup', ['$event'])
  mouseDown(e: MouseEvent) {
    this.onMouseDown.emit({ component: this, event: e });
    e.preventDefault();
    e.returnValue = false;

    let linkNodeValue =
      this.elementRef.nativeElement.attributes['ng-reflect-router-link']
        ?.nodeValue;

    let wheelClickLink = linkNodeValue != undefined;

    // Check si l'app est lancée en mode 'dev' ou 'prod'
    if (!isDevMode()) {
      wheelClickLink = wheelClickLink || this.routerLink != undefined;

      if (linkNodeValue == undefined) {
        linkNodeValue = '';
      }
    }

    if (e.button == 1 && e.which === 2 && wheelClickLink) {
      // Mettre à jour la valeur de l'attribut routerLink
      this.renderer.setAttribute(
        this.elementRef.nativeElement,
        'routerLink',
        this.routerLink,
      );

      linkNodeValue =
        typeof this.routerLink != 'string'
          ? this.routerLink[0]
          : this.routerLink;

      //on prépare les url et le boolean pour la boucle while
      let arrayUrlBase = linkNodeValue.replace('./', '').split('/');
      let arrayUrl = this._router.url.split('/');
      let boolUrl = false;

      // tant qu'on ne trouve pas la route correct on slice le dernier élément avec un garde fou
      while (!boolUrl) {
        if (this.isRouteExists(arrayUrl)) {
          boolUrl = true;
        } else {
          if (arrayUrl.length == 1) {
            boolUrl = true;
          } else {
            arrayUrl = arrayUrl.slice(0, -1);
          }
        }
      }

      // Si le garde fou n'est pas passé on aura un tableau de taille supérieur à 0
      if (arrayUrl.length >= 1) {
        arrayUrl = [...arrayUrl, ...arrayUrlBase];
        arrayUrl = Array.from(new Set(arrayUrl)); // Supprime les doublons
        const url = this._router.createUrlTree(arrayUrl);
        window.open(this._router.serializeUrl(url), '_blank');
      } else {
        console.warn(
          'URL non trouvée dans les routes configurées:',
          arrayUrl.join('/'),
        );
      }
    }
  }

  /** Propage le clique. */
  click(e?) {
    if (this.active != true) {
      if (this.source != undefined && this.filterLive) {
        this.source.datasource.reload();
      }
      this.elementRef.nativeElement.click();
      //this.active = true;
      this.onClick.emit({ component: this, event: e });
      if (e && e.defaultPrevented === false) {
        this.active = true;
        this.cd.detectChanges();
      }
    }
  }

  deleteCumulFilter(e) {
    this.haveFilter = false;
    this.onItemSelectionChanged.emit(this.filters([]));
    this.treeView.instance.unselectAll();
    this.treeView.instance.collapseAll();
    this.refreshView = undefined;
    this.removeFilterName();
  }

  private filterIndicator(parameterType: string): boolean {
    this.getCumulFilterList();
    let cumulFilterKey = this.cumulateFilterList.includes(parameterType);
    return cumulFilterKey;
  }

  private removeFilterName() {
    this.cumulateFilterList = this.cumulateFilterList.filter(
      (x) => x != this.parameterType,
    );
    sessionStorage.setItem(
      'cumulFilter',
      JSON.stringify(this.cumulateFilterList),
    );
  }

  private unSelectedNodeParentId(parentNode: dxTreeViewNode, result = []) {
    let parentId = parentNode?.itemData?.parentId;
    if (parentId != undefined && parentId != null) {
      result.push(parentId);
      this.unSelectedNodeParentId(parentNode?.parent, result);
    }
    return result;
  }

  private getCumulFilterList() {
    let cumulateFilterArray = sessionStorage.getItem('cumulFilter');
    if (cumulateFilterArray != undefined && cumulateFilterArray != null) {
      this.cumulateFilterList = JSON.parse(
        cumulateFilterArray,
      ) as Array<string>;
    }
  }

  private applySortOrder(loadDataSource: boolean = false) {
    if (!Array.isArray(this.source) && this.source?.datasource != undefined) {
      this.source.datasource.sort([
        { selector: this.displayExpr, desc: false },
      ]);
      if (loadDataSource) {
        this.source.datasource.load();
      }
    }
  }
}
