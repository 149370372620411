import { EventEmitter, Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  CheckBoxOptions,
  CompareOptions,
  Control,
  DropDownBoxOptions,
  FileManagerOptions,
  FileUploaderOptions,
  FormLayout,
  GridOptions,
  LinkListOptions,
  PageControl,
  PageGroup,
  PageSection,
  PageTab,
  SchedulerOptions,
  Section,
  SubFormLinkList,
  Validator,
  VisibleSelectableLinkEntityListOptions,
} from '@clarilog/shared2/models/schema';
import {
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import * as dot from 'dot-object';
import { Subscription } from 'rxjs';
import { CallbackFunction } from '../../../services/compiler/model-compiler-context.service';
import { TranslatedFieldHelperService } from '../../translate-field';
import { FormDependsOnService } from './form-depends-on.service';
import { FormGroupHelpers } from './form-group-helpers';
import { FormAnyLinqArray, FormLinkArray } from './form-link-array';
import { CustomValidators } from './work-form-validations';

/** Représente le service qui permet de créer les @type {FormGroups} et @type {FormControls}. */
@Injectable({
  providedIn: 'root',
})
export class CoreFormLoader {
  private _controlChangesUnsubscribe: Subscription[] = [];
  private _id: number = 0;
  private onLoad: EventEmitter<any> = new EventEmitter<any>();
  private firstControl: UntypedFormControl;
  private _controlChanges: EventEmitter<any> = new EventEmitter();
  private readOnly: boolean = false;

  constructor(
    private formDependsOnService: FormDependsOnService,
    private translateFieldService: TranslatedFieldHelperService,
  ) {}

  async create(
    modelState: ModelState,
    readOnly: boolean = false,
  ): Promise<UntypedFormGroup> {
    this.readOnly = readOnly;
    // Initialisation de l'id.
    this._id = 0;
    this.firstControl = undefined;
    let form = await this.mapLayoutIntoFormGroup(
      modelState,
      modelState.model.form.layout,
    );
    form.markAsUnloaded();

    modelState.form = form;

    this.dependsOn(form, modelState.model.form.layout, modelState);
    FormGroupHelpers.fixFormGroupVisibility(form);

    return form;
  }

  private cleanDataFromTypeName(data: any): any {
    if (data == undefined) {
      return;
    }

    if (data['__typename'] != undefined) {
      //delete data['__typename']
    }

    if (Array.isArray(data)) {
      (data as Array<any>).forEach((el) => {
        if (typeof el == 'object') {
          el = this.cleanDataFromTypeName(el);
        }
      });
    } else {
      let keys = Object.keys(data);

      keys.forEach((key) => {
        if (typeof data[key] == 'object') {
          data[key] = this.cleanDataFromTypeName(data[key]);
        }
      });
    }
    return data;
  }

  public load(
    formGroup: UntypedFormGroup,
    data: any,
    reload: boolean = false,
    dataAsDirty: boolean = false,
  ) {
    let controls = FormGroupHelpers.formControls(formGroup);
    data = this.cleanDataFromTypeName(data);
    for (let control of controls) {
      if (!(control.value instanceof FormLinkArray)) {
        let key = control.key;

        if (key != '__typename') {
          key = key.replace(/_/g, '.');
        }
        let value = dot.pick(key, data);

        if (value == undefined && key.indexOf('.') > 0) {
          key = control.key;
          value = dot.pick(key, data);
        }

        if (value != undefined) {
          if (reload !== true) {
            control.value.originalValue = JSON.parse(JSON.stringify(value));
          }
          if (control.value !== value) {
            control.value.setValue(value);

            if (reload === true || dataAsDirty === true) {
              control.value.markAsDirty();
            }
          }
        } else {
          if (
            data != undefined &&
            data.id != undefined &&
            control.value.originalValue != value
          ) {
            control.value.reset();
          }
        }
      } else if (
        control.value instanceof FormLinkArray &&
        control.key == 'fileIds' &&
        control.value.validator != undefined
      ) {
        let fn = control.value.validator({} as AbstractControl);
        if (fn != undefined) {
          if (data?.fileIds == undefined || data.fileIds.length == 0) {
            control.value.updateValueAndValidity({
              onlySelf: true,
              emitEvent: true,
            });
          }
        }
      }
    }
  }

  /** Permet de gérer les dependsOn. */
  private dependsOn(
    formGroup: UntypedFormGroup,
    layout: FormLayout,
    modelState: ModelState,
  ) {
    this.formDependsOnService.create(formGroup, layout, modelState);
  }

  convertToOperator(
    operator:
      | 'Equal'
      | 'NotEqual'
      | 'GreaterThan'
      | 'GreaterThanOrEqual'
      | 'LessThan'
      | 'LessThanOrEqual',
  ) {
    switch (operator) {
      case 'Equal':
        return '=';
      case 'NotEqual':
        return '<>';
      case 'GreaterThan':
        return '>';
      case 'GreaterThanOrEqual':
        return '>=';
      case 'LessThan':
        return '<';
      case 'LessThanOrEqual':
        return '<=';
    }
  }

  /** Map le layout au FormGroup. */
  private async mapLayoutIntoFormGroup(
    modelState: ModelState,
    layout: FormLayout,
  ): Promise<UntypedFormGroup> {
    return new Promise<UntypedFormGroup>(async (resolve, reject) => {
      let formGroup = await this.mapPagesIntoFormGroup(
        modelState,
        layout.pages,
      );
      if (layout.systemControls != undefined) {
        for (let control of layout.systemControls.controls) {
          let formControl = await this.mapControl(modelState, control);
          formGroup.addControl(control.name, formControl);
        }
      }
      if (layout.defaultFieldName == undefined)
        layout.defaultFieldName = 'name';
      if (
        layout.defaultFieldName != undefined &&
        typeof layout.defaultFieldName === 'string'
      ) {
        let fieldName = layout.defaultFieldName;
        let control = FormGroupHelpers.formControlByName(formGroup, fieldName);
        if (control == undefined && layout.translatableTitle === true) {
          fieldName =
            this.translateFieldService.setColumnTranslateField(fieldName);

          fieldName = fieldName.replace(/\./g, '_');
          control = FormGroupHelpers.formControlByName(formGroup, fieldName);
        }

        if (control != undefined) {
          if (layout.translatableTitle === true) {
            control.isTranslatable = true;
          }
          control.isDefault = true;
        }
      } else if (
        layout.defaultFieldName != undefined &&
        typeof layout.defaultFieldName === 'function'
      ) {
        formGroup.isDefault = layout.defaultFieldName;
      } else if (this.firstControl != undefined) {
        this.firstControl.isDefault = true;
      }
      resolve(formGroup);
    });
  }

  /** Map les pages au FormGroup. */
  private async mapPagesIntoFormGroup(
    modelState: ModelState,
    pages: (PageSection | PageControl | PageGroup | PageTab)[],
  ): Promise<UntypedFormGroup> {
    return new Promise(async (resolv, reject) => {
      let pagesFormGroup: any = new UntypedFormGroup({});

      for (let page of pages) {
        let sections = (page as PageSection).sections;
        let groupPages = (page as PageGroup).pages;
        let control = (page as PageControl).control;
        let tabs = (page as PageTab).tabs;

        page.name = `page${this._id++}`;

        let ctrl: UntypedFormGroup = null;
        if (sections !== undefined) {
          ctrl = await this.mapPageSectionsIntoFormControl(
            modelState,
            sections,
          );
        } else if (groupPages !== undefined) {
          ctrl = await this.mapPagesIntoFormGroup(modelState, groupPages);
        } else if (control !== undefined) {
          if (
            this.readOnly &&
            (control?.disableReadOnlyMode == undefined ||
              control?.disableReadOnlyMode === false)
          ) {
            control.readOnly = this.readOnly;
          }
          ctrl = await this.mapControlsIntoFormGroup(modelState, control);
        } else if (tabs !== undefined) {
          ctrl = await this.mapPagesIntoFormGroup(modelState, tabs);
        }
        if (ctrl != undefined) {
          if (page.userVisible != undefined && page.userVisible === false) {
            ctrl.invisible();
          }
          pagesFormGroup.addControl(page.name, ctrl);
        }
      }
      resolv(pagesFormGroup);
    });
  }

  /** Map les controls. */
  private async mapControl(
    modelState: ModelState,
    control: Control,
  ): Promise<UntypedFormControl> {
    // Vérification si le fieldName appartient un formGroup
    // (ex: scanConfiguration.auditOption.sinceDay).
    this.initializeControl(control);

    let syncValidators = this.createSyncValidator(control, modelState);
    let isTranslatable = false;
    if (control.type == 'TranslateFieldComponent') {
      control.isTranslatable = true;
      control.translatable = true;
      isTranslatable = true;
    }
    let asyncValidators = this.createAsyncValidator(
      control.fieldName,
      control.validators,
      isTranslatable,
      control.type,
    );
    let defaultValue = undefined;

    if (modelState.id == undefined) {
      defaultValue = await this.setDefaultValue(control);
    }
    if (defaultValue != undefined && defaultValue != '') {
      if (
        !(control.managed != undefined && control.managed == false) &&
        !(control.type == 'TranslateFieldComponent' && control.readOnly == true)
      ) {
        modelState.sharedContext.entry.set(
          control.fieldName,
          () => defaultValue,
        );
      }
    }

    // Créé le FormControl associé au Control du model.
    let formControl: UntypedFormControl = new UntypedFormControl(
      {
        value: defaultValue,
        disabled: control.disabled,
      },
      syncValidators,
      asyncValidators,
    );

    formControl.componentType = control.type;
    if (control.visible === false) {
      formControl.invisible();
    }

    if (
      control.forceSendValue != undefined &&
      control.forceSendValue === true
    ) {
      formControl['forceSendValue'] = control.forceSendValue;
    }

    if (control.managed != undefined && control.managed === false) {
      formControl['managed'] = control.managed;
    }
    if (
      control.translatable === true ||
      (control.options != undefined &&
        control.options['translatable'] === true) ||
      control.type === 'TranslateFieldComponent'
    ) {
      formControl.isTranslatable = true;
    }

    if (
      control.type === 'TranslateFieldComponent' &&
      control.readOnly == true
    ) {
      formControl['readOnly'] = control.readOnly;
    }

    if (control?.disableReadOnlyMode != undefined) {
      formControl['disableReadOnlyMode'] = control['disableReadOnlyMode'];
    }

    // Prend le premier control pour mapper le control par défaut si non spécifié.
    if (this.firstControl === undefined) {
      this.firstControl = formControl;
    }
    // Valeur par défaut est la valeur d'origine.
    formControl.originalValue = defaultValue;
    // Enregistre le label pour pouvoir le réutiliser.
    formControl.label = control.label;

    // Si le control possède des validators.
    if (typeof formControl.validator === 'function') {
      let fn = formControl.validator({} as AbstractControl);
      if (fn != undefined) {
        let required = fn.required;
        // Enregistre le require pour pouvoir le réutiliser.
        formControl.required = required != undefined ? required : false;
      }
    }

    // Ajoute le control au FormGroup.
    return formControl;
  }

  /** Map les sections au FormControl. */
  private async mapPageSectionsIntoFormControl(
    modelState: ModelState,
    sections: Section[],
  ): Promise<UntypedFormGroup> {
    let pageFormGroup = new UntypedFormGroup({});
    for (let section of sections) {
      for (let group of section.groups) {
        let groupFormGroup = new UntypedFormGroup({});
        for (let control of group.controls) {
          if (
            this.readOnly &&
            (control?.disableReadOnlyMode == undefined ||
              control?.disableReadOnlyMode === false)
          ) {
            control.readOnly = this.readOnly;
          }
          let formControl = await this.mapControl(modelState, control);
          if (control.type == 'FileManagerComponent') {
            let syncValidators = this.createSyncValidator(control, modelState);
            let asyncValidators = this.createAsyncValidator(
              control.fieldName,
              control.validators,
              false,
              control.type,
            );
            groupFormGroup.addControl(
              control.name,
              new FormLinkArray(
                modelState,
                control.options as FileManagerOptions,
                control.disabled,
                true,
                syncValidators,
                asyncValidators,
              ),
            );
          } else {
            groupFormGroup.addControl(control.name, formControl);
          }
        }
        group.name = `group${this._id++}`;
        // Ajoute le FormGroup au FormGroup.
        pageFormGroup.addControl(group.name, groupFormGroup);
      }
    }
    return pageFormGroup;
  }

  /** Map les controls au FormGroup. */
  private async mapControlsIntoFormGroup(
    modelState: ModelState,
    control: Control,
  ): Promise<UntypedFormGroup> {
    let fg = new UntypedFormGroup({});
    this.initializeControl(control);
    // TODO implement other type spec
    // VisibleSelectableLinkEntity
    if (
      control.type === 'LinkListComponent' ||
      control.type === 'CheckLinkListComponent'
    ) {
      fg.addControl(
        control.name,
        new FormLinkArray(
          modelState,
          control.options as LinkListOptions,
          control.disabled,
        ),
      );
    } else if (control.type === 'VisibleSelectableLinkEntityComponent') {
      fg.addControl(
        control.name,
        new FormLinkArray(
          modelState,
          control.options as VisibleSelectableLinkEntityListOptions,
          control.disabled,
        ),
      );
    } else if (control.type === 'LogicDiagramComponent') {
      let formControl = new UntypedFormControl(null, Validators.required);
      // let formControl = new FormControl({ nodes: [], edges: [] });
      fg.addControl(control.name, formControl);
    } else if (control.type === 'CoreSubFormLinkListComponent') {
      let syncValidators = this.createSyncValidator(control, modelState);
      let asyncValidators = this.createAsyncValidator(
        control.fieldName,
        control.validators,
        false,
        control.type,
      );
      fg.addControl(
        control.name,
        new FormLinkArray(
          modelState,
          control.options as SubFormLinkList,
          control.disabled,
          true,
          syncValidators,
          asyncValidators,
        ),
      );
    } else if (control.type === 'CoreSubFormGanttComponent') {
      let syncValidators = this.createSyncValidator(control, modelState);
      let asyncValidators = this.createAsyncValidator(
        control.fieldName,
        control.validators,
        false,
        control.type,
      );
      fg.addControl(
        control.name,
        new FormLinkArray(
          modelState,
          control.options as SubFormLinkList,
          control.disabled,
          true,
          syncValidators,
          asyncValidators,
        ),
      );
    } else if (control.type === 'DesignerRuleListComponent') {
      let syncValidators = this.createSyncValidator(control, modelState);
      let asyncValidators = this.createAsyncValidator(
        control.fieldName,
        control.validators,
        false,
        control.type,
      );
      let defaultValue = await this.setDefaultValue(control);
      if (defaultValue != undefined && defaultValue != '') {
        if (!(control.managed != undefined && control.managed == false)) {
          modelState.sharedContext.entry.set(
            control.fieldName,
            () => defaultValue,
          );
        }
      }
      // Créé le FormControl associé au Control du model.
      let formControl: UntypedFormControl = new UntypedFormControl(
        {
          value: defaultValue,
          disabled: control.disabled,
        },
        syncValidators,
        asyncValidators,
      );

      if (
        control.forceSendValue != undefined &&
        control.forceSendValue === true
      ) {
        formControl['forceSendValue'] = control.forceSendValue;
      }
      fg.addControl(control.name, formControl);
    } else if (control.type === 'StockListComponent') {
      let formControl = new UntypedFormControl([]);
      fg.addControl(control.name, formControl);
    } else if (control.type === 'StockCreatedAssetListComponent') {
      let formControl = new UntypedFormControl([]);
      fg.addControl(control.name, formControl);
    } else if (control.type === 'SchedulerComponent') {
      fg.addControl(
        control.name,
        new FormLinkArray(
          modelState,
          control.options as SchedulerOptions,
          control.disabled,
        ),
      );
    } else if (control.type === 'PermissionsListComponent') {
      // let formControl = new FormArray([]);
      // fg.addControl(control.fieldName, formControl);
      let formControl = new UntypedFormControl({
        value: [],
        disabled: control.disabled != undefined ? control.disabled : false,
      });
      fg.addControl(control.name, formControl);
    } else if (control.type === 'GridComponent') {
      if ((control.options as GridOptions)?.saveItems != undefined) {
        // Cas du grid sans save
        let formControl = new FormAnyLinqArray(
          modelState,
          control.options as GridOptions,
        );
        if (control.managed != undefined && control.managed === false) {
          formControl['managed'] = control.managed;
        }
        fg.addControl(control.name, formControl);

        let unsubscribe = formControl.valueChanges.subscribe((val) => {
          this._controlChanges.emit({ control: control, value: val });
        });

        this._controlChangesUnsubscribe.push(unsubscribe);
      } else {
        // Cas du grid sans save
        let formControl = new UntypedFormControl([]);
        if (control.managed != undefined && control.managed === false) {
          formControl['managed'] = control.managed;
        }
        fg.addControl(control.name, formControl);

        let unsubscribe = formControl.valueChanges.subscribe((val) => {
          this._controlChanges.emit({ control: control, value: val });
        });

        this._controlChangesUnsubscribe.push(unsubscribe);
      }
    } else if (control.type === 'CommandAssetCategoryListComponent') {
      let formControl = new UntypedFormControl([]);
      fg.addControl(control.name, formControl);
    } else if (control.type === 'FileManagerComponent') {
      let syncValidators = this.createSyncValidator(control, modelState);
      let asyncValidators = this.createAsyncValidator(
        control.fieldName,
        control.validators,
        false,
        control.type,
      );
      fg.addControl(
        control.name,
        new FormLinkArray(
          modelState,
          control.options as FileManagerOptions,
          control.disabled,
          true,
          syncValidators,
          asyncValidators,
        ),
      );
    } else if (control.type === 'FileUploaderComponent') {
      fg.addControl(
        control.name,
        new FormLinkArray(modelState, control.options as FileUploaderOptions),
      );
    } else if (
      control.type === 'TicketEmailListComponent' ||
      control.type === 'MessageTicketComponent'
    ) {
      let asyncValidators = this.createAsyncValidator(
        control.fieldName,
        control.validators,
        false,
        control.type,
      );

      let formControl = new UntypedFormControl('', undefined, asyncValidators);
      formControl.originalValue = '';
      fg.addControl(control.name, formControl);
    }
    // Pour tous les autres composants.
    else if (control.name !== undefined) {
      let formControl = new UntypedFormControl();
      formControl.originalValue = '';
      fg.addControl(control.name, formControl);
    }

    if (control.visible === false) {
      fg.controls[control.name].invisible();
    }

    if (control?.disableReadOnlyMode != undefined) {
      fg.controls[control.name]['disableReadOnlyMode'] =
        control['disableReadOnlyMode'];
    }

    return fg;
  }

  /** Initialise le nom du control. */
  private initializeControl(control: Control) {
    // Si pas de fieldName, alors génération d'un controlId
    if (control.fieldName == undefined) {
      control.fieldName = `control${this._id++}`;
    }
    control.name = control.fieldName;
    if (control.name != '__typename') {
      control.name = control.name.replace(/\./g, '_');
    }
  }

  /** Permet d'initialiser la valeur par défaut,
   * si aucune value n'est spécifiée alors undefined est retourné. */
  private async setDefaultValue(control: Control): Promise<any> {
    let defaultValue = undefined;

    if (control.defaultValue) {
      if (control.defaultValue instanceof ModelFnContext) {
        // ATTENTION la valeur returner doit etre en toPromise()
        (control.defaultValue as ModelFnContext).context.params.set(
          'fieldName',
          () => control.fieldName,
        );
        defaultValue = await (control.defaultValue as ModelFnContext).fnCall();
        (control.defaultValue as ModelFnContext).context.params.remove(
          'fieldName',
        );
      } else {
        defaultValue = control.defaultValue;
      }
    } else {
      switch (control.type) {
        case 'TextBoxComponent':
          defaultValue = '';
          break;
        case 'TextAreaComponent':
          defaultValue = '';
          break;
        case 'NumberBoxComponent':
          defaultValue = 0;
          break;
        case 'DateTimeComponent':
          if (control.defaultValue != undefined) {
            defaultValue = new Date(control.defaultValue as any);
          } else {
            defaultValue = undefined;
          }
          break;
        case 'CalendarComponent':
          if (control.defaultValue != undefined) {
            defaultValue = new Date(control.defaultValue as any);
          } else {
            defaultValue = undefined;
          }
          break;
        case 'ColorBoxComponent':
          defaultValue = null;
          break;
        case 'CheckBoxComponent':
          let checkBoxOptions = control.options as CheckBoxOptions;
          if (checkBoxOptions?.useIndeterminateState === true) {
            defaultValue = undefined;
          } else {
            defaultValue = false;
          }
          break;
        case 'SwitchComponent':
          defaultValue = false;
          break;
        case 'HtmlEditorComponent':
          defaultValue = '';
          break;
        case 'HtmlEditorTranslatedComponent':
          defaultValue = this.translateFieldService.getDefaultvalue();
          break;
        case 'HtmlEditorSimpleTranslatedComponent':
          defaultValue = this.translateFieldService.getDefaultvalue();
        case 'TranslateFieldComponent':
          control.isTranslatable = true;
          control.translatable = true;
          defaultValue = this.translateFieldService.getDefaultvalue();
          break;
        case 'SelectBoxComponent':
          defaultValue = '';
          break;
        case 'AutoCompleteBoxComponent':
          defaultValue = '';
          break;
        case 'TagBoxComponent':
          defaultValue = [];
          break;
        case 'TagBoxCustomItemComponent':
          defaultValue = [];
          break;
        case 'DropDownBoxComponent':
          let options = control.options as DropDownBoxOptions;
          if (options.multiple === true) {
            defaultValue = [];
          } else {
            defaultValue = null;
          }
          break;
        default:
          defaultValue = undefined;
          break;
      }
    }

    return defaultValue;
  }

  /** Créé des validateurs asynchrone. */
  private createAsyncValidator(
    fieldName: string,
    validators: Validator[],
    isTranlate: boolean = false,
    controlType: string = undefined,
  ): AsyncValidatorFn[] {
    let validatorFns: AsyncValidatorFn[] = [];
    if (validators != undefined) {
      for (let validator of validators) {
        let name = validator.name;
        switch (name) {
          case 'unique':
            validatorFns.push(
              CustomValidators.unique(
                fieldName,
                (<any>validator.value) as ModelFnContext,
                isTranlate,
              ),
            );
            break;
          case 'hierarchicalUnique':
            validatorFns.push(
              CustomValidators.hierarchicalUnique(
                fieldName,
                validator,
                (<any>validator.value) as ModelFnContext,
                <CallbackFunction<boolean>>(<unknown>validator.value),
                isTranlate,
              ),
            );
            break;
          case 'oneOrMoreAsync':
            validatorFns.push(
              CustomValidators.oneOrMoreAsync(
                ((<any>validator.value) as ModelFnContext).fnCall(),
                ((<any>validator.value) as ModelFnContext).rootState,
                controlType,
              ),
            );
            break;
        }
      }
    }
    return validatorFns;
  }

  /** Créé des validateurs synchrone. */
  private createSyncValidator(
    control: Control,
    modelState: ModelState,
  ): ValidatorFn[] {
    let validatorFns: ValidatorFn[] = [];
    if (control?.validators != undefined) {
      for (let validator of control.validators) {
        let name = validator.name;
        switch (name) {
          case 'required':
            validatorFns.push(Validators.required);
            break;
          case 'notNullOrEmpty':
            validatorFns.push(
              CustomValidators.notNullOrEmpty(modelState, control),
            );
            break;
          case 'ipAddress':
            validatorFns.push(CustomValidators.ipAddress());
            break;
          case 'requiredTrue':
            validatorFns.push(Validators.requiredTrue);
            break;
          case 'requiredLicense':
            validatorFns.push(CustomValidators.requiredLicense());
            break;
          case 'pattern':
            validatorFns.push(Validators.pattern(<string>validator.value));
            break;
          case 'customPattern':
            validatorFns.push(
              CustomValidators.CustomPattern(
                <string>validator.value,
                validator.message,
              ),
            );
            break;
          case 'min':
            validatorFns.push(Validators.min(<number>validator.value));
            break;
          case 'max':
            validatorFns.push(Validators.max(<number>validator.value));
            break;
          case 'minLength':
            validatorFns.push(Validators.minLength(<number>validator.value));
            break;
          case 'maxLength':
            validatorFns.push(Validators.maxLength(<number>validator.value));
            break;
          case 'email':
            validatorFns.push(Validators.email);
            break;
          case 'compare':
            let options = <CompareOptions>validator.value;
            options.operator = options.operator || 'Equal';
            validatorFns.push(CustomValidators.compare(options));
            break;
          case 'requiredMatrix':
            validatorFns.push(CustomValidators.requiredMatrix());
            break;
          case 'emailArray':
            validatorFns.push(CustomValidators.emailArray(validator.message));
            break;
        }
      }
    }
    return validatorFns;
  }

  /** Libère les ressources. */
  dispose() {
    for (let change of this._controlChangesUnsubscribe) {
      change.unsubscribe();
    }
  }
}
