import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { GC, GCFactory } from '@clarilog/core';
import { CriteriaHelpers } from '@clarilog/core/services2/graphql/criteria-helpers';
import {
  GqlField,
  GqlFields,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { CommonCoreService } from '@clarilog/core/services2/graphql/generated-types/services/common.service';
import {
  PropertyField,
  WorkflowPropertyValue,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { CoreGraphQLSource } from '@clarilog/core/services2/graphql/graphql-store.service';
import {
  ModelDataSourceContext,
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { Observable, of } from 'rxjs';
import { FilterBuilderExtensionService } from '../filter-builder/services/filter-builder-extension.service';
import { FormGroupHelpers } from '../form/work-form/form-group-helpers';
import { TranslatedFieldHelperService } from '../translate-field/translate-field-helper-service';
import { CompilerHelpers } from '@clarilog/shared2/services/compiler/compiler.helpers';

@Component({
  selector: 'clc-property-change',
  templateUrl: './property-change.component.html',
  styleUrls: ['./property-change.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PropertyChangeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PropertyChangeComponent),
      multi: true,
    },
  ],
})
export class PropertyChangeComponent
  implements OnInit, ControlValueAccessor, OnDestroy, Validator
{
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};

  field: PropertyField;
  allFields: PropertyField[];
  _gc: GC;

  GridOrTreeFormat: string = 'Grid';
  @Input() state: ModelState;
  @Input() type: string;
  @Input() fieldName: string;
  /** Represente la methode pour ajouter des champs au type substitution ajout */
  @Input() fieldTypeToAdd: ModelFnContext;
  /** Represente la methode pour eclure les champs pour l'action "vider" */
  @Input() clearFieldToExclude: ModelFnContext;
  /** Represente la liste des champs à type substitution ajout */
  _fieldTypeToAdd: any;
  /** Represente la liste des champs à eclure pour l'action "vider" */
  _clearFieldToExclude: any;

  operatorFieldNames = [
    'operatorId',
    'operatorAffectedId',
    'operatorReferentId',
  ];

  @Input() showFilterContidion = false;

  constructor(
    private commonService: CommonCoreService,
    private gcFact: GCFactory,
    private serviceInjector: Injector,
    private cd: ChangeDetectorRef,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private filterBuilderExtensionService: FilterBuilderExtensionService,
  ) {
    this._gc = gcFact.create();
    this.resetForm();
  }

  validate(control: AbstractControl): ValidationErrors {
    if (control.value == undefined || control.value.editType == undefined) {
      return { required: true };
    }

    return null;
  }

  ngOnDestroy(): void {
    this._gc.dispose();
  }
  expression: any;
  value: string;
  valueDate: string;
  editType: string;
  name: string;

  selectDataSourceContext: ModelDataSourceContext;
  selectValueDataSourceContext: ModelDataSourceContext;
  selectSubstitueDataSourceContext: ModelDataSourceContext;
  subValueAddDatasource: any;
  selectData: { key: string; displayName: string }[] = [];

  resetForm() {
    this.editType = undefined;
    this.value = '';
    this.selectValueDataSourceContext = undefined;
    this.selectData = [];
    this.expression = undefined;
  }

  getDisplayExpr(field: PropertyField) {
    if (
      this.editType === 'value' &&
      this.operatorFieldNames.find((x) => field.completeName.includes(x)) !=
        undefined
    ) {
      return 'displayOperatorFilter';
    }
    
    let displayExpr = field.displayExpression;
    if (displayExpr == undefined) {
      displayExpr = 'name';
    }

    if (field.isTranslatable) {
      displayExpr =
        this.translatedFieldHelperService.setColumnTranslateField(displayExpr);
    }

    return displayExpr;
  }

  loadFieldInfo(fieldName) {
    this.name = '';
    if (fieldName != undefined) {
      if (fieldName.indexOf('.data.') > 0) {
        fieldName = fieldName.replace('.data.', '.');
      }

      this.field = this.allFields.find(
        (x) => x.keyName === fieldName || x.name === fieldName,
      );

      this.name = JSON.stringify(this.field);
      this.selectData = [];
      this.selectData.push({
        key: 'value',
        displayName: TranslateService.get('entities/specificValue'),
      });

      let fieldclearValue = true;
      if (
        this._clearFieldToExclude != undefined &&
        this._clearFieldToExclude.includes(fieldName)
      ) {
        fieldclearValue = false;
      }

      if (fieldclearValue == true) {
        this.selectData.push({
          key: 'clearValue',
          displayName: TranslateService.get('entities/clearValue'),
        });
      }

      if (this.field.type == 'DateTime') {
        this.selectData.push({
          key: 'dateTimeNowValue',
          displayName: TranslateService.get('entities/dateTimeNowValue'),
        });
      }

      if (
        this._fieldTypeToAdd != undefined &&
        this._fieldTypeToAdd.includes(fieldName)
      ) {
        if (this.field.serviceName == undefined) {
          this.filterBuilderExtensionService.getCustomFieldId(this.field);
        }
        if (this.field.serviceName != undefined) {
          this.field.hasChildren = true;
        }
      }

      if (this.field != undefined && this.field.hasChildren) {
        let sameFields = this.allFields.filter(
          (x) =>
            x.serviceName === this.field.serviceName &&
            x.keyPathName != this.field.keyPathName &&
            x.isList === this.field.isList,
        );

        if (sameFields.length > 0) {
          this.selectData.push({
            key: 'substitute-value',
            displayName: TranslateService.get('entities/subValue'),
          });
        }

        this.selectValueDataSourceContext = new ModelDataSourceContext({
          serviceName: this.field.serviceName,
          methodName: 'find',
        });

        this.selectValueDataSourceContext.datasource = CoreGraphQLSource.create(
          {
            context: this.selectValueDataSourceContext,
            query: (filter?: any, options?: any) => {
              //-Mettre dans l'ordre
              if (this.field.isTranslatable) {
                let lang = {};
                lang[this.translatedFieldHelperService.getTranslateKey()] =
                  'ASC';
                let sort = { name: lang };
                options.sort = [];
                options?.sort.push(sort);
              } else {
                options?.sort.push({ name: 'ASC' });
              }

              if (this.editType === 'value') {
                let serviceName =
                  this.field.serviceName.split('Service')[0].toPascalCase() +
                  'CoreService';

                // TODO check
                let service = this.serviceInjector.get(serviceName);
                let myFilter =
                  filter != undefined
                    ? CriteriaHelpers.convertDxFilter(
                        CriteriaHelpers.decompile(filter),
                      )
                    : null;

                let displayField: GqlField | GqlSubField = GqlField.create(
                  this.field.displayExpression,
                );

                if (this.field.isTranslatable === true) {
                  displayField = GqlSubField.create(
                    this.field.displayExpression,
                    [
                      GqlField.create(
                        this.translatedFieldHelperService.getTranslateKey(),
                      ),
                    ],
                  );
                }

                let fields = [
                  GqlField.create(this.field.valueExpression),
                  displayField,
                ];

                if (
                  this.operatorFieldNames.find((x) => fieldName.includes(x)) !=
                  undefined
                ) {
                  fields.push(GqlField.create('email'));
                  fields.push(GqlField.create('serviceDeskActivated'));
                  fields.push(GqlField.create('displayOperatorFilter'));
                }

                fields = this.addNewFields(fields);

                let gql = [
                  GqlSubField.create('data', fields),
                  GqlField.create('totalCount'),
                ];

                if (
                  this.operatorFieldNames.find((x) => fieldName.includes(x)) !=
                  undefined
                ) {
                  options['sort'] = [{ name: 'ASC' }];
                }

                // Appel du findbyqualification uniquement si présent et avec un type
                this.selectValueDataSourceContext.context.params.remove('type');

                let defaultService = 'find';
                let hasType = this.state?.sharedContext?.params?.get('type');
                if (
                  service['findByQualification'] != undefined &&
                  hasType != undefined
                ) {
                  switch (hasType.toLowerCase()) {
                    case 'ticket':
                    case 'request':
                    case 'problem':
                    case 'incident':
                      defaultService = 'findByQualification';

                      this.selectValueDataSourceContext.context.params.set(
                        'type',
                        () => hasType,
                      );
                      break;
                    default:
                      hasType = undefined;
                  }
                }

                // Vérifie si une source custom est définie
                let args = CompilerHelpers.getParams(service[defaultService]);
                var params = [];
                var allcheck = true;

                args.forEach((f) => {
                  if (f.includes('fields')) params.push(gql);
                  else if (f.includes('qualification')) params.push(hasType);
                  else if (f.includes('options')) params.push(options);
                  else if (f.includes('filter')) params.push(myFilter);
                  else allcheck = false;
                });

                let defaultSource = service[defaultService].apply(
                  service,
                  params,
                );

                if (service['propertyChangeCustomSource'] != undefined) {
                  return service['propertyChangeCustomSource'](
                    defaultSource,
                    this.field.completeName,
                    gql,
                    options,
                    myFilter,
                  ) as Observable<any>;
                }

                return defaultSource;
              } else if (this.editType === 'substitute-value') {
                let entriesNames = [];
                let entriesToDel = [];
                sameFields.map((x) => {
                  let entry = {};
                  let translatedName = x.name
                    .split('.')
                    .map((y, index) =>
                      TranslateService.get(
                        'entities/' +
                          (index === 0 ? 'incident' : x.parentType) +
                          '/' +
                          y,
                      ),
                    )
                    .join(' / ');
                  if (translatedName.includes('[') && x.keyName != undefined) {
                    translatedName = x.keyName
                      .split('.')
                      .map((y, index) =>
                        TranslateService.get(
                          'entities/' +
                            (index === 0 ? 'incident' : x.parentType) +
                            '/' +
                            y,
                        ),
                      )
                      .join(' / ');
                  }

                  entry[this.field.displayExpression ?? 'name'] =
                    translatedName;

                  entry[this.field.valueExpression ?? 'id'] =
                    x.keyName ?? x.name;

                  entriesNames.push(entry);

                  if (entry['name'].includes('[')) entriesToDel.push(x.name);

                  return entry;
                });

                sameFields = sameFields.filter(
                  (f) => entriesToDel.find((e) => e === f.name) === undefined,
                );

                let resultingFields = [];
                let fieldsToRemove = this.removeSubstituteValue(fieldName);

                sameFields.forEach((field) => {
                  let res = {
                    irefProperty: field.irefProperty,
                    subIrefProperty: field.subIrefProperty,
                    serviceName: field.serviceName,
                    name: field.name,
                    isEnum: field.isEnum,
                    isList: field.isList,
                    isNumeric: field.isNumeric,
                    isTranslatable: field.isTranslatable,
                    isClarilogModel: field.isClarilogModel,
                    hasQualification: field.hasQualification,
                    displayExpression: field.displayExpression,
                    valueExpression: 'id',
                    parentType: field.parentType,
                    keyName: field.keyName,
                    keyPathName: field.keyPathName,
                    id: field.keyName ?? field.name,
                    parentId: field.parentId,
                    hasChildren: field.hasChildren,
                    completeName: field.completeName,
                    canFilter: field.canFilter,
                    treeField: field.treeField,
                    translatedName: entriesNames.find(
                      (e) => e.id === (field.keyName ?? field.name),
                    )[field.displayExpression ?? 'name'],
                  };

                  resultingFields.push(res);
                });

                if (this.operatorFieldNames.includes(fieldName)) {
                  resultingFields.push(this.getConnectedOperatorField());
                } else if (
                  fieldName === 'operatorTeamReferentId' ||
                  fieldName === 'operatorTeamAffectedId' ||
                  fieldName == 'operatorTeamId'
                ) {
                  resultingFields.push(
                    this.getConnectedOperatorMainTeamField(),
                  );
                }
                resultingFields = resultingFields.filter(
                  (field) => !fieldsToRemove.includes(field.name),
                );

                resultingFields.sort((a, b) =>
                  a.translatedName.localeCompare(b.translatedName),
                );

                if (filter != null) {
                  var dataSource = new DataSource({
                    store: {
                      type: 'array',
                      key: 'id',
                      data: resultingFields,
                    },
                  });
                  dataSource.filter(filter);
                  dataSource.reload();
                  resultingFields = dataSource.items();
                }
                return of({
                  data: resultingFields,
                  totalCount: resultingFields.length,
                });
              }

              return of({ data: [], totalCount: 0 });
            },
          },
        );
      }

      if (
        this._fieldTypeToAdd != undefined &&
        this._fieldTypeToAdd.includes(fieldName) &&
        this.field.type == 'String'
      ) {
        let sameFieldTypeToAdd = this.allFields.filter(
          (x) => x.parentType == this.type && this.field.type == x.type,
        );
        let subValueAddData = [];
        for (let field of sameFieldTypeToAdd) {
          let translatedName = TranslateService.get(
            'entities/' + this.type + '/' + field.name,
          );
          if (!translatedName.startsWith('[')) {
            subValueAddData.push({
              key: field.name,
              displayName: translatedName,
            });
          }
        }

        if (subValueAddData.length > 0) {
          this.subValueAddDatasource = new ArrayStore({
            data: subValueAddData,
            key: 'key',
          });
          this.selectData.push({
            key: 'substitute-value-add',
            displayName: TranslateService.get('entities/subValueAdd'),
          });
        }
      }
    }

    this.selectDataSourceContext.datasource.reload();
    if (
      this.selectValueDataSourceContext != undefined &&
      this.selectValueDataSourceContext.datasource != undefined &&
      this.editType != undefined
    ) {
      this.selectValueDataSourceContext.datasource.reload();
    }
  }

  getConnectedOperatorField() {
    return {
      canFilter: true,
      completeName: 'ticketOperatorConnectedId',
      displayExpression: 'id',
      hasChildren: true,
      hasQualification: false,
      id: '[ticketOperatorConnectedProperty].id',
      irefProperty: undefined,
      isClarilogModel: false,
      isEnum: false,
      isList: false,
      isNumeric: false,
      isTranslatable: true,
      keyName: '[ticketOperatorConnectedProperty].id',
      keyPathName: 'ticketOperatorConnectedId',
      name: 'ticketOperatorConnected',
      parentId: null,
      parentName: 'user',
      serviceName: 'user',
      subIrefProperty: undefined,
      translatedName: TranslateService.get(`entities/role/operatorConnected`),
      treeField: null,
      valueExpression: 'name',
    };
  }

  getConnectedOperatorMainTeamField() {
    return {
      canFilter: true,
      completeName: 'ticketOperatorConnectedMainTeamId',
      displayExpression: 'id',
      hasChildren: true,
      hasQualification: false,
      id: '[ticketOperatorConnectedProperty].MainOperatorTeamId',
      irefProperty: undefined,
      isClarilogModel: false,
      isEnum: false,
      isList: false,
      isNumeric: false,
      isTranslatable: true,
      keyName: '[ticketOperatorConnectedProperty].MainOperatorTeamId',
      keyPathName: 'ticketOperatorConnectedMainTeamId',
      name: 'ticketOperatorConnectedMainTeamId',
      parentId: null,
      parentName: 'user',
      serviceName: 'user',
      subIrefProperty: undefined,
      translatedName: TranslateService.get(
        `entities/role/operatorConnectedMainTeam`,
      ),
      treeField: null,
      valueExpression: 'name',
    };
  }

  ngOnInit(): void {
    let ctrl = FormGroupHelpers.formControlByName(
      this.state.form,
      'propertyName',
    );

    this.selectDataSourceContext = new ModelDataSourceContext({
      serviceName: 'alarmService',
      methodName: 'find',
    });

    this.selectDataSourceContext.datasource = CoreGraphQLSource.create({
      context: this.selectDataSourceContext,
      query: (filter?: any, options?: any) => {
        return this.selectData;
      },
    });

    //     force le type

    let defineEntitype = this.state.sharedContext.params.get('forceType');
    if (defineEntitype != undefined) {
      this.type = defineEntitype;
    }
    if (
      this.state?.sharedContext?.params?.get('showFilterContidion') === true
    ) {
      this.showFilterContidion = true;
    }

    this.commonService
      .allFields(
        this.commonService.gqlField(),
        this.type.charAt(0).toUpperCase() + this.type.substring(1),
      )
      .subscribe(async (res) => {
        this._gc.forDispose(
          ctrl.valueChanges.subscribe((value) => {
            if (value != ctrl.originalValue) {
              this.resetForm();
              this.loadFieldInfo(value);
            }
          }),
        );

        this.allFields = res.data;
        const idxObj = this.allFields.findIndex((obj) => {
          switch (obj.name) {
            case 'duration':
              return obj.name === 'duration';
            case 'estimate':
              return obj.name === 'estimate';
          }
        });
        this.allFields.splice(idxObj, 1);

        this.loadFieldInfo(ctrl.value);
      });

    if (this.fieldTypeToAdd != undefined) {
      this._fieldTypeToAdd = this.fieldTypeToAdd.fnCall();
      for (let field of this._fieldTypeToAdd) {
        if (field.indexOf('.data.') > 0) {
          let index = this._fieldTypeToAdd.indexOf(field);
          this._fieldTypeToAdd[index] = field.replace('.data.', '.');
        }
      }
    }

    if (this.clearFieldToExclude != undefined) {
      this._clearFieldToExclude = this.clearFieldToExclude.fnCall();
    }
  }

  writeValue(obj: WorkflowPropertyValue): void {
    if (obj != undefined) {
      this.editType = obj.editType;
      this.value = obj.value;
      this.expression = obj.expression;
      if (Date.parse(this.value)) {
        this.valueDate = this.value;
      }
    }

    this.cd.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      throw new Error('Method not implemented.');
    }
  }

  editTypeSelectionChanged(e) {
    if (this.editType == 'dateTimeNowValue' || this.editType == 'clearValue') {
      let val: WorkflowPropertyValue = {
        replaceOnlyEmpty: false,
        changeValue: true,
        value: '',
        editType: this.editType,
        expression: this.expression,
      };
      this.onChange(val);
      this.onTouched();
    } else {
      if (
        this.selectValueDataSourceContext != undefined &&
        this.editType != undefined
      ) {
        this.selectValueDataSourceContext.datasource.reload();
      }
    }
  }

  valueSelectionChanged(e) {
    if (e != undefined && e.value != undefined) {
      this.value = e.value;
    } else {
      this.value = e;
    }

    let val: WorkflowPropertyValue = {
      replaceOnlyEmpty: false,
      changeValue: true,
      value: this.value,
      editType: this.editType,
      expression: this.expression,
    };

    this.onChange(val);
    this.onTouched();
  }

  onFilterValueChanged(e) {
    if (e != undefined && e.value != undefined) {
      this.expression = e.value;
    } else {
      this.expression = e;
    }

    if (this.expression != undefined) {
      this.expression = JSON.stringify(this.expression);
    }

    let val: WorkflowPropertyValue = {
      replaceOnlyEmpty: false,
      changeValue: true,
      value: this.value,
      editType: this.editType,
      expression: this.expression,
    };

    this.onChange(val);
    this.onTouched();
  }

  valueDateSelectionChanged(e) {
    let value = e.value;
    if (e.value.toISOString != undefined) {
      value = e.value.toISOString();
    }
    let val: WorkflowPropertyValue = {
      replaceOnlyEmpty: false,
      changeValue: true,
      value: value,
      editType: this.editType,
      expression: this.expression,
    };

    this.onChange(val);
    this.onTouched();
  }

  valueNumberSelectionChanged(e) {
    let value = e.value.toString();
    let val: WorkflowPropertyValue = {
      replaceOnlyEmpty: false,
      changeValue: true,
      value: value,
      editType: this.editType,
      expression: this.expression,
    };

    this.onChange(val);
    this.onTouched();
  }

  addNewFields(fields: GqlFields): GqlFields {
    //liste des elements qui peuvent s'afficher sous forme d'arbo
    let listServiceTree = ['ticketCategory', 'organizationalUnit', 'location'];
    let isTreeformat = listServiceTree.find(
      (x) => x === this.field.name?.toString(),
    );

    if (isTreeformat) {
      fields.push(GqlField.create('parentId'));
      this.GridOrTreeFormat = 'Tree';
    }

    return fields;
  }

  /**Liste des valeurs de substitution à enlever */
  private removeSubstituteValue(fieldNameProperty: string): Array<string> {
    let userAffected = ['operatorAffected', 'operatorReferent'];
    let operator = [...userAffected, 'reminder.user'];

    let user = [
      'userAffected',
      'userMakeRequest',
      'reminder.user',
      'mainImpactedAsset.ownerId',
    ];

    let userMainOperator = [
      'userAffected.mainOperatorTeamId',
      'userMakeRequest.mainOperatorTeamId',
    ];

    let propertyTask = [
      'operator.revokeBy',
      'ticket.userMakeRequest',
      'ticket.userAffected',
    ];

    let propertiesFieldsToRemove = [
      {
        name: 'mainImpactedAssetId',
        fields: [
          'operatorAffected.mainAssetId',
          'operatorReferent.mainAssetId',
        ],
      },
      {
        name: 'userMakeRequestId',
        fields: operator,
      },
      {
        name: 'operatorTeamAffectedId',
        fields: userMainOperator,
      },
      {
        name: 'operatorTeamReferentId',
        fields: userMainOperator,
      },
      {
        name: 'operatorAffectedId',
        fields: user,
      },
      {
        name: 'operatorReferentId',
        fields: user,
      },
      {
        name: 'userAffectedId',
        fields: userAffected,
      },
      {
        name: 'operatorId',
        fields: propertyTask,
      },
    ];

    var element = propertiesFieldsToRemove.find(
      (x) => x.name.toLowerCase() == fieldNameProperty.toLowerCase(),
    );

    return element != null ? element.fields : [];
  }
}
