import {
  AfterContentInit,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Router,
  ActivatedRoute,
  UrlTree,
  NavigationExtras,
} from '@angular/router';
import { ServiceListResult } from '@clarilog/core';
import {
  CoreGraphQLDataSource,
  GraphQLStore,
} from '@clarilog/core/services2/graphql/graphql-store.service';
import { Filter, ToolbarItem } from '@clarilog/shared2/models';
import {
  ModelDataSourceContext,
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { of } from 'rxjs';
import { TranslateService } from '../../../services/translate/translate.service';
import { ItemsValue } from '../../form/work-form/form-link-array';
import { CoreListComponent } from '../list/list.component';
import { ListComponentBase } from '../list/list.component-base';
import { CoreSelectListComponent } from '../select-list/select-list.component';
import { AssetCoreService } from '@clarilog/core/services2/graphql/generated-types/services/asset.service';
import {
  GqlSubField,
  GqlField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { CorePolicyValidator } from '@clarilog/core/services2/authorization/authorization-policy-builder.service';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';

/**
 * Représente l'état dans lequel se trouve le composent LinkList.
 */
export enum LinkListMode {
  /** État normal. */
  Current,
  /** État ajout. */
  Added,
  /** État supprimé */
  Removed,
}
/** Représente le composent LinkList. Permet de lier des éléments à un élément. */
@Component({
  selector: 'clc-link-list',
  templateUrl: './link-list.component.html',
  styleUrls: ['./link-list.component.scss'],
  viewProviders: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreLinkListComponent),
      multi: true,
    },
  ],
})
export class CoreLinkListComponent
  extends ListComponentBase
  implements ControlValueAccessor, AfterContentInit, OnInit
{
  /** Représente la valeur. */
  _value: ItemsValue;
  /** Obtient ou définit le mode d'affichage. */
  public mode: LinkListMode = LinkListMode.Current;
  /** Obtient ou définit la source de données courante. */
  currentSource: ModelDataSourceContext = new ModelDataSourceContext({
    datasource: new DataSource([]),
  });
  /** Obtient ou définit la source du select. */
  @Input() selectSource: ModelDataSourceContext;
  /** Obtient ou définit la query globale. */
  @Input() query: any = undefined;
  /** Obtient ou définit le modele state */
  @Input() modelState: ModelState;
  // /** Obtient ou définit les éléments ajoutés. */
  @Input() itemsAdded: any[] = [];
  // /** Obtient ou définit les éléments supprimés. */
  @Input() itemsRemoved: any[] = [];
  /** Obtient ou définit si une action de delete existe. */
  @Input() canDeleted: boolean = false;
  @Input() canDissociated: boolean = true;
  /** Obtient ou définit l'état d'ouverture du popup. */
  @Input() associatePopup: boolean = false;

  /** Obtient ou définit le nom du label du control */
  @Input() label: string = undefined;
  /** Obtient ou définit la query du select. */
  @Input() select: any = undefined;
  /** Obtient le composant liste associé. */
  @ViewChild(CoreSelectListComponent, { static: true })
  selectList: CoreSelectListComponent;
  /** Obtient ou définit le composent list. */
  @ViewChild(CoreListComponent, { static: true }) list: CoreListComponent;
  /** Obtient ou définit la valeur de la route de gestion */
  @Input() route: string;
  /** Obtient ou définit l'état activé du composent. */
  @Input() disabled: boolean;
  /** Obtient ou définit le titre d'un nouveau bouton */
  @Input() titleButton: string;
  /** Obtient ou définit la route d'un nouveau bouton */
  @Input() routeButton: string;
  /** Obtient ou définit la route d'un nouveau bouton */
  @Input() policiesButton: string;
  /** Obtient ou définit le nom du query params de la route d'un nouveau bouton */
  @Input() queryParamsFieldIdButton: string;
  /** Active ou désactive le bouton 'Nouveau Prêt' */
  @Input() activeButton: boolean = false;
  /** Utile pour l'enum dans la vue. */
  public LinkListMode: typeof LinkListMode = LinkListMode;
  /** Obtient ou définit la source de données par défaut. */
  @Input() defaultSource: ModelDataSourceContext = undefined;
  /** obtient ou définit le fonction permettant s'executant avant la suppression. */
  @Input() beforeRemoveItems: any = undefined;
  /** Obtient ou définit le message affiché lorsque l'on ne peut pas supprimer. */
  @Input() beforeRemoveItemsMessage: string;
  /** Obtient ou définit une valuer indiquant si la mise à jour en live est activée. */
  @Input() isLiveUpdated: boolean = false;
  /** Obtiens définis les filtres */
  @Input() filters: Filter[];
  /** Obtient ou définit si le formulaire est en read Only. */
  @Input() readOnly: boolean = false;

  /** Obtient ou définit si le formulaire est en read Only. */
  @Input() addSource: boolean = true;
  /** Obtient ou définit si le formulaire est en read Only. */
  @Input() removeSource: boolean = true;
  /** Obtient ou définit le message affiché en tete. */
  @Input() hint: string;

  /**Obtient ou définit si on affiche le menu (nav menu) des filtres que dans le pop-up d'association */
  @Input() displayFilterOnlyForPopup: boolean = false;

  @Input() allowExportPanel: boolean = true;
  @Input() isMobile: boolean = false;

  /**
   * Obtient ou définit les boutons d'actions.
   */
  @Input() toolbarItems?: any[];
  @Input() canSelect: boolean;

  filterForSelect: Filter[];

  initSelectedGrid: boolean = false;
  originalSelectedKey: string[];

  /**Obtient ou définit le nom du filtre utilisé dans le contexte d'exécution */
  filterContextName: string = undefined;

  /** Obtient ou définit la valeur. */
  get value(): ItemsValue {
    return this._value;
  }
  set value(value: ItemsValue) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  public _localStorageService: LocalStorageService;

  constructor(
    private assetService: AssetCoreService,
    private _router: Router,
    private router: ActivatedRoute,
    public _route: ActivatedRoute,
    private policyValidator: CorePolicyValidator,
    private localStorageService: LocalStorageService,
  ) {
    super();
    this._localStorageService = localStorageService;
    this.isMobile = this._localStorageService.isMobile as boolean;
  }

  private findFirstFilter(filters: Filter[]): Filter {
    if (filters[0].items != undefined && filters[0].items.length > 0) {
      return this.findFirstFilter(filters[0].items);
    } else {
      return filters[0];
    }
  }

  /** @inheritdoc */
  ngOnInit() {
    // <fix>
    // TODO A optimiser
    this.currentFilter =
      this.filters != undefined ? this.findFirstFilter(this.filters) : null;

    if (this.filters != undefined && this.selectSource != undefined) {
      this.filterForSelect = [];

      for (let i = 0; i < this.filters.length; i++) {
        this.filterForSelect.push({
          items: [],
          list: undefined,
          text: this.filters[i].text,
        });

        if (this.filters[i].items != undefined) {
          for (let y = 0; y < this.filters[i].items.length; y++) {
            if (this.filters[i].items[y].list != undefined) {
              this.filterForSelect[i].items.push({
                text: this.filters[i].items[y].text,
                source: this.filters[i].items[y].source,
                inContext: this.filters[i].items[y].inContext,
                filterExpr: this.filters[i].items[y].filterExpr,
                parentIdExpr: this.filters[i].items[y].parentIdExpr,
                description: this.filters[i].items[y].description,
                keyExpr: this.filters[i].items[y].keyExpr,
                displayExpr: this.filters[i].items[y].displayExpr,
                multiple: this.filters[i].items[y].multiple,
                filterOperator: this.filters[i].items[y].filterOperator,
                recursive: this.filters[i].items[y].recursive,
                command: this.filters[i].items[y].command,
                filterLive: this.filters[i].items[y].filterLive,
                filterContext: this.filters[i].items[y].filterContext,
                list: {
                  source: <any>new ModelDataSourceContext({
                    datasource: this.selectSource.datasource,
                  }),
                },
                visible: this.filters[i].items[y].visible,
              });
            }
          }
        }

        if (this.filters[i].list != undefined) {
          this.filterForSelect[i].list = {
            source: <any>new ModelDataSourceContext({
              datasource: this.selectSource.datasource,
            }),
          };
        }
      }

      // Force le context
      if (this.filterForSelect != undefined) {
        this.filterForSelect.forEach((f) => {
          if (
            f.list?.source != undefined &&
            (f.list?.source as ModelDataSourceContext)
          ) {
            (<ModelDataSourceContext>f.list.source).context =
              this.modelState.sharedContext;
          } else {
            if (f.items != undefined) {
              f.items.forEach((g) => {
                if (
                  g.list?.source != undefined &&
                  (g.list?.source as ModelDataSourceContext)
                ) {
                  (<ModelDataSourceContext>g.list.source).context =
                    this.modelState.sharedContext;
                }
              });
            }
          }
        });
      }
    }

    let query = (this.source.datasource.store() as GraphQLStore).context.context
      .config.query;
    let defaultQuery = undefined;
    if (this.defaultSource != undefined) {
      defaultQuery = (this.defaultSource.datasource.store() as GraphQLStore)
        .context.context.config.query;
    }
    if (this.source.datasource instanceof CoreGraphQLDataSource) {
      (
        this.source.datasource.store() as GraphQLStore
      ).context.context.config.query = (...args) => {
        if (this.sharedContext.params.get('id') != undefined) {
          return query.apply(
            (this.source.datasource.store() as GraphQLStore).context.context
              .config,
            args,
          );
        } else {
          if (defaultQuery != undefined) {
            return defaultQuery.apply(
              (this.defaultSource.datasource.store() as GraphQLStore).context
                .context.config,
              args,
            );
          } else {
            return of(<ServiceListResult<any>>{
              data: [],
              totalCount: 0,
              errors: [],
            });
          }
        }
      };
    }

    // </fix>
    this.list.sourceChanged = true;
    this.currentSource = new ModelDataSourceContext({
      datasource: this.source.datasource,
    });

    //Initialisation du titre pour le nouveau bouton
    if (this.titleButton == undefined || this.titleButton == null) {
      this.titleButton = TranslateService.get('new');
    }

    if (this.policiesButton != undefined) {
      this.activeButton = this.policyValidator.validate(this.policiesButton);
    }
  }

  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** @inheritdoc */
  writeValue(value: any): void {
    if (value != undefined) {
      this.value = value;
    }
    let hasBeenReset = false;
    if (value.itemsRemoved.length === 0) {
      hasBeenReset = true;
      this.itemsRemoved.clear();
    }
    if (value.itemsAdded.length === 0) {
      hasBeenReset = true;
      this.itemsAdded.clear();
    }

    if (value.itemsAdded.length > 0) {
      value.itemsAdded.forEach((value) => this.itemsAdded.push(value));
      this.setItemsAdded(this.itemsAdded.map((ai) => ai['id']));
      this.addedList();
      this.refresh();
    }

    if (hasBeenReset === true) {
      this.mode = LinkListMode.Current;
      this.current();
      if (this.list.isInitialized === true) {
        this.list.clear();
        this.refresh();
      }
    }
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  /** Permet de modifier les items de add. */
  private setItemsAdded(items: any[]) {
    this._value.itemsAdded = items;
    this.onChange(this._value);
    this.onTouched();
  }
  /** Permet de modifier les items de remove. */
  private setItemsRemoved(items: any[]) {
    this._value.itemsRemoved = items;
    this.onChange(this._value);
    this.onTouched();
  }
  /** @inheritdoc */
  public ngAfterContentInit(): void {
    if (this.selectList !== undefined) {
      this.selectList.onSelect.subscribe((results: any[]) => {
        results.forEach((value) => this.itemsAdded.push(value));
        this.setItemsAdded(this.itemsAdded.map((ai) => ai['id']));
        this.mode = LinkListMode.Added;
        this.addedList();
        this.associatePopup = false;
      });
    }
  }
  /** Dissocie les éléments sélectionnés. */
  async dissociate(e) {
    let canDissociate = true;
    if (this.beforeRemoveItems != undefined) {
      this.beforeRemoveItems.context.params.set(
        'items',
        () => this.selectedData,
      );
      canDissociate = await this.beforeRemoveItems.fnCall().toPromise();
      this.beforeRemoveItems.context.params.remove('items');

      if (canDissociate === false) {
        if (this.beforeRemoveItemsMessage.indexOf('[resource(') > 0) {
          this.beforeRemoveItemsMessage = TranslateService.get(
            this.beforeRemoveItemsMessage,
          );
        }
        notify(this.beforeRemoveItemsMessage, 'error', 5000);
      }
    }

    if (canDissociate === true) {
      this.selectedData.forEach((value) => this.itemsRemoved.push(value));
      this.setItemsRemoved(this.itemsRemoved.map((ai) => ai['id']));
      await this.list.clear();
      await this.refresh();
    }
  }

  currentSourceFilter: any;

  /** Rafraîchi la liste. */
  public refresh() {
    this.currentSourceFilter = [];
    for (let item of this.itemsRemoved) {
      this.currentSourceFilter.push(['id', '<>', item['id']]);
      if (this.itemsRemoved.indexOf(item) < this.itemsRemoved.length - 1) {
        this.currentSourceFilter.push('and');
      }
    }

    if (this.mode === LinkListMode.Current) {
      this.current();

      if (this.type == undefined || this.type == 'Grid') {
        this.list.refresh();
      } else {
        this.currentSource.datasource.reload();
      }
    } else if (this.mode === LinkListMode.Added) {
      this.addedList();
    } else if (this.mode === LinkListMode.Removed) {
      this.removedList();
    }
  }

  /** Affiche les données en cours de l'élément. */
  public async current() {
    setTimeout(() => {
      this.selectedKeys = [];
      this.list.sourceChanged = true;
      this.currentSource = new ModelDataSourceContext({
        datasource: new DataSource(this.source.datasource.store()),
      });
      if (
        this.currentSourceFilter != undefined &&
        this.currentSourceFilter.length > 0
      ) {
        this.currentSource.datasource.filter(this.currentSourceFilter);
      }
    });
  }
  /** Affiche les éléments à ajouter. */
  public addedList() {
    this.itemsAdded.forEach((item) => {
      if (typeof item == 'string') {
        this.assetService
          .get([GqlSubField.create('data', [GqlField.create('id')])], item)
          .subscribe((c) => {
            this.itemsAdded = this.itemsAdded.filter(
              (item) => item !== c.data.id,
            );
            this.itemsAdded.push(c.data);
          });
      }
    });

    this.setItemsAdded(this.itemsAdded.map((ai) => ai['id']));
    this.list.sourceChanged = true;
    this.currentSource = new ModelDataSourceContext({
      datasource: new DataSource(this.itemsAdded),
    });
  }
  /** Affiche les éléments à supprimer. */
  public removedList() {
    this.list.sourceChanged = true;
    this.currentSource = new ModelDataSourceContext({
      datasource: new DataSource(this.itemsRemoved),
    });
  }
  /** Annule soit un ajout soit une suppression. */
  public cancel(e) {
    this.selectedData.forEach((value) => {
      if (this.mode === LinkListMode.Added) {
        let index = this.itemsAdded.indexOf(value);
        this.itemsAdded.splice(index, 1);
        this.onChange(this.value);
      } else {
        let index = this.itemsRemoved.indexOf(value);
        this.itemsRemoved.splice(index, 1);
        this.onChange(this.value);
      }
    });

    if (this.mode === LinkListMode.Added) {
      if (this.itemsAdded.length === 0) {
        this.setItemsAdded([]);
        this.mode = LinkListMode.Current;

        this.current();
      } else {
        this.setItemsAdded(this.itemsAdded.map((ai) => ai['id']));
      }
    } else {
      if (this.itemsRemoved.length === 0) {
        this.setItemsRemoved([]);
        this.mode = LinkListMode.Current;
        this.current();
      } else {
        this.setItemsRemoved(this.itemsRemoved.map((ai) => ai['id']));
      }
    }
    this.refresh();
  }
  /** Lorsque le popup se ferme, remise à zéro des données de la liste de sélection. */
  public hidden(e) {
    //this.selectList.clear();
  }

  getPopupWidth(): string | number {
    //Si plus de 6 colonnes, on agrandit la popup
    const columnLimit = 6;
    if (this.columns.length > columnLimit) {
      return '80%';
    }
    if (!this.filters || (this.filters.length === 1 && this.filters[0]?.items?.length === 1)) {
      return 800;
    }
    
    return 1050;
  }

  /** Lorsque la fenêtre s'affiche, le dataSource est créé avec un nouveau filtre. */
  public show(e) {
    let filters: any[] = [];

    for (let item of this.itemsRemoved) {
      if (filters.length > 0) {
        filters.push('and');
      }
      filters.push(['id', '<>', item['id']]);
    }
    for (let item of this.itemsAdded) {
      if (filters.length > 0) {
        filters.push('and');
      }
      filters.push(['id', '<>', item['id']]);
    }
    if (this.selectList.source === undefined) {
      this.selectList.source = this.selectSource;
      this.selectList.initModel();
    }

    if (filters.length > 0) {
      this.selectList.filter(filters);
    }

    this.selectList.refresh();
  }

  /** Se déclenche sur le click du bouton accéder. */
  public async onGoTo(defaultRoute: boolean = false) {
    let id = this.router.snapshot.paramMap.get('id');
    /** liste les différents paramètre à intégrer dans l'url */
    let queryParams: object = {};
    if (id != null && this.queryParamsFieldIdButton != undefined) {
      queryParams[this.queryParamsFieldIdButton] = id;
    }

    let navigate = this.getUrl();
    let url = this._router.createUrlTree([navigate], {
      skipLocationChange: true,
    } as NavigationExtras);

    if (defaultRoute && this.routeButton != undefined) {
      url = this._router.createUrlTree([this.routeButton], {
        queryParams: queryParams,
        skipLocationChange: true,
      } as NavigationExtras);
    }
    let win = window.open(this._router.serializeUrl(url), '_blank');
    win.opener.callback = async () => await this.refresh();
  }

  /** Obtient l'url du route */
  getUrl() {
    let navigate = this.route;
    if (typeof this.route == 'object') {
      let functionRoute: ModelFnContext;
      functionRoute = this.route;
      functionRoute.context.params.set('item', () => undefined);
      let datatype = this.modelState?.sharedContext?.params?.get('types');

      if (datatype != undefined) {
        functionRoute.context.params.set('types', () => datatype);
      }
      functionRoute.fnCall().subscribe((data) => {
        navigate = data;
      });
    }

    return navigate;
  }

  currentFilter;

  onNavFilterClick(e) {
    this.list.sourceChanged = true;
    this.currentSource = this.currentFilter.list.source;
    this.list.isLiveUpdated = this.currentFilter.list.liveUpdate;
    if (this.filterContextName != undefined) {
      this.modelState.sharedContext.params.set(
        this.filterContextName,
        () => [],
      );
    }
  }

  onItemSelectionChanged(e) {
    delete window.history?.state?.filters;
    if (this.currentSource == undefined) {
      this.list.sourceChanged = true;
      this.currentSource = this.currentFilter.list.source;
    }
    if (e !== undefined && e.filters && e.filters.length > 0) {
      this.currentSource.datasource.filter([e.filters]);
    } else {
      this.currentSource.datasource.filter(null);
    }
    // TODO : Pas de reload car sinon les template explose
    // ou alors laisser reload mais avec un repaint
    this.list.refresh();
  }

  onRowListClick(e) {
    /** liste les différents paramètre à intégrer dans l'url */
    let addRouteUrl = undefined;
    let typeData = e?.data?.__typename;
    let params = undefined;
    /** Cas spécifique */

    if (typeData != undefined) {
      switch (typeData) {
        case 'Software':
          switch (e?.data?.softwareCategory) {
            case 'PROGRAM':
              addRouteUrl = 'software';
              break;
            case 'OPERATING_SYSTEM':
              addRouteUrl = 'operatingSystems';
              break;
            case 'UPDATE':
              addRouteUrl = 'updates';
              break;
            case 'WORK_APPLICATION':
              addRouteUrl = 'workApplications';
              break;
          }
          break;
        case 'SecurityGroup':
          params = { type: 'group' };
          break;
        case 'Role':
          params = { type: 'role' };
          break;
      }
    }
    let url: UrlTree;
    let id: string;
    if (typeof e.value == 'object') {
      id = e.value.id;
    } else {
      id = e.value;
    }
    if (id != '00000000-0000-0000-0000-000000000000') {
      let navigate = this.getUrl();
      if (addRouteUrl != undefined) {
        url = this._router.createUrlTree([navigate, addRouteUrl, 'edit', id], {
          queryParams: params,
          skipLocationChange: true,
        } as NavigationExtras);
      } else {
        url = this._router.createUrlTree([navigate, 'edit', id], {
          queryParams: params,
          skipLocationChange: true,
        } as NavigationExtras);
      }
      let win = window.open(this._router.serializeUrl(url), '_blank');
      win.opener.callback = async () => await this.refresh();
    }
  }

  //Envoie les éelements sélectionnés dans le filtre (gauche) au contexte d'exécution
  onItemSelected(e: { name: string; data: string[] }) {
    this.modelState.sharedContext.params.set(e.name, () => e.data);
    this.filterContextName = e.name;
  }

  /** Supprime les lien */
  findColumns() {
    if (this.columns != undefined) {
      let col = JSON.parse(JSON.stringify(this.columns));
      col.forEach((c) => {
        c.link = false;
      });
      return col;
    }
    return [];
  }
}
