import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrganizationCoreService } from '@clarilog/core/services2/graphql/generated-types/services';
import { TranslateService } from '@clarilog/shared';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { UserIdleService } from 'angular-user-idle';
import { CookieStorage } from 'cookie-storage';
import notify from 'devextreme/ui/notify';
import { Notification, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthentificationConfiguratorService } from './auth.config';
import { Router } from '@angular/router';

/** Représente la classe permettant de gérer les droits d'accés. */
@Injectable({
  providedIn: 'root',
})
export class AuthentificationService {
  cookieStorage: CookieStorage;

  constructor(
    private oauthService: OAuthService,
    private userIdle: UserIdleService,
    private _authConfigService: AuthentificationConfiguratorService,
    private router: Router
  ) {
    // For debugging:

    localStorage.removeItem('serviceDesk');

    this.oauthService.events.subscribe((e) => {
      if (
        (e instanceof OAuthErrorEvent &&
          e.reason instanceof HttpErrorResponse &&
          e.reason.status === 401) ||
        e.type === 'token_validation_error' ||
        e.type === 'token_error'
      ) {
        if (window.location.pathname != '/') {
          let redirectUrl = window.location.pathname;
          if (window.location.search) {
            redirectUrl += window.location.search;
          }
          localStorage.setItem('redirectUrl', redirectUrl);
        }
        if (localStorage.getItem("mpc_token") != undefined) {
          this.oauthService.initImplicitFlow("use_ms_login");
        } else {
          this.oauthService.initImplicitFlow();

        }

      }
      if (e.type === 'token_received') {
        let redirectUrl = localStorage.getItem('redirectUrl');
        if (redirectUrl != undefined) {
          localStorage.removeItem('redirectUrl');
          window.location.href = window.location.origin + redirectUrl;
        }
      }
      if (e instanceof OAuthErrorEvent && e.type === 'invalid_nonce_in_state') {
        if (localStorage.getItem('invite_token') != undefined) {
          //Redirige avec le token d'invitation
          window.location.href =
            window.location.origin +
            '?inviteToken=' +
            localStorage.getItem('invite_token');
        } else {
          const port = window.location.port;
          const hostName = window.location.hostname;

          let host = hostName.split('.');

          host[0] = port === '4200' ? 'main' : 'www';

          window.location.href =
            window.location.protocol + '//' + host.join('.') + ':' + port;
        }
      }
    });

    this.oauthService.setStorage(sessionStorage);
    this.oauthService.configure(this._authConfigService.authConfig);
    this.oauthService.setupAutomaticSilentRefresh();

    // Start watching when user idle is starting.
    this.startWatching();
    this.userIdle.onTimerStart().toPromise();
    this.userIdle.onTimeout().subscribe(() => {
      notify(
        TranslateService.get('globals/logOutInactivity'),
        'warning',
        30000,
      );
      this.stopWatching();
      this.stop();
      this.disconnect();
    });
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }
  disconnect() {
    this.oauthService.logOut(false);
  }
  get oAuthService(): OAuthService {
    return this.oauthService;
  }
  /** Récupère les informations de l'utilisateur connecté. */
  get userInfo(): any {
    return this.oauthService.getIdentityClaims();
  }
  /**
   * Obtient le baerer d'authorisation (token)
   * @returns  Token de connexion
   */
  getAuthorizationHeaderValue(): string {
    return 'Bearer ' + this.oauthService.getAccessToken();
  }
  /** Permet de se déconnecter de l'application. */
  logout() {
    this.oauthService.logOut(false);
  }
    
  redirectToErrorPage() {
    return this.router.navigateByUrl('/unauthorized'); 
  }

  /**
   * Permet d'initialiser l'authentification.
   * https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/blob/master/src/app/core/auth.service.ts
   */
  initialize(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.oauthService.events
        .pipe(filter((e) => ['token_received'].includes(e.type)))
        .subscribe(() => {
          this.oauthService
            .loadUserProfile()
            .then(() => {
              observer.next(true);
            })
            .finally(() => observer.complete());
        });
      this.oauthService.events
        .pipe(filter((e: any) => e.type === 'invalid_nonce_in_state'))
        .subscribe(() => {
          this.oauthService.initImplicitFlow();
        });
      // Load information from Auth0 (could also be configured manually)
      this.oauthService
        .loadDiscoveryDocument()
        .then(() => this.oauthService.tryLogin())
        .then(() => {
          if (!this.oauthService.hasValidAccessToken()) {
            this.oauthService
              .silentRefresh()
              .then((info) => console.debug('refresh ', info))
              .catch((e) => {
                // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
                // Only the ones where it's reasonably sure that sending the
                // user to the IdServer will help.
                const errorResponsesRequiringUserInteraction = [
                  'interaction_required',
                  'login_required',
                  'account_selection_required',
                  'consent_required',
                ];

                if (
                  e &&
                  e.reason &&
                  errorResponsesRequiringUserInteraction.indexOf(
                    e.reason.error,
                  ) >= 0
                ) {
                  // 3. ASK FOR LOGIN:
                  // At this point we know for sure that we have to ask the
                  // user to log in, so we redirect them to the IdServer to
                  // enter credentials.
                  //
                  // Enable this to ALWAYS force a user to login.
                  // this.oauthService.initImplicitFlow();
                  //
                  // Instead, we'll now do this:
                  this.oauthService.initImplicitFlow();
                }
              });
          } else {
            this.oauthService
              .loadUserProfile()
              .then(() => {
                observer.next(true);
              })
              .finally(() => observer.complete());
          }
        });
    });
  }
}
