import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import {
  AlarmTriggerTypeEnum,
  AuditType,
  DynamicPropertyLocationEnum,
  PhoneEnum,
} from '../types';
import { EnumCoreBaseService } from './enum.service-base';
import { LdapObjectClassType } from '@clarilog/core/services/graphql/graphql-types';
@Injectable({ providedIn: 'root' })
/** Service Global de gestion des enums */
export class EnumCoreService extends EnumCoreBaseService {

  constructor(
  ) {
    super();
  }
  
  /** Type de téléphone */
  phoneTypes(): { id: string; label: string }[] {
    let results = Object.values(PhoneEnum).map((m) => {
      let display = TranslateService.get(`enums/phone/${m.toString()}`);
      if (display.startsWith('[')) {
        display = `${m.toString()}`;
      }
      return {
        id: m,
        label: display,
      };
    });
    return results;
  }

  getRoleTypeAllowedIp() {
    let type = [
      {
        id: 'Manager',
        name: TranslateService.get('entities/role/managerTypeLabel'),
      },
      {
        id: 'HelpDeskOperator',
        name: TranslateService.get('entities/role/operatorTypeLabel'),
      },
      {
        id: 'HelpDeskUser',
        name: TranslateService.get('entities/role/userTypeLabel'),
      }
    ];
    return type;
  }

  /** Obtient les valeur de l'enum ScanExcludeCategory */
  auditTypeFilter(): any[] {
    return this.toEnum(AuditType, 'auditType').filter(
      (x) => x.id != 'UNKNOW' && x.id != 'NONE',
    );
  }

  resolutionModelType(): any[] {
    return [
      {
        id: 'public',
        name: TranslateService.get('enums/resolutionType/public'),
      },
      {
        id: 'private',
        name: TranslateService.get('enums/resolutionType/private'),
      },
    ];
  }

  alarmTrigger(): any[] {
    return this.toEnum(AlarmTriggerTypeEnum, 'alarmTriggerTypeEnum').filter(
      (x) => x.id != 'TASKS',
    );
  }

  emailModelType(): any[] {
    return [
      {
        id: 'CUSTOMPASSWORD',
        value: TranslateService.get('entities/user/customPassword'),
      },
      {
        id: 'WITHOUTPASSWORD',
        value: TranslateService.get('entities/user/withoutPassword'),
      },
      {
        id: 'WITHOUTEMAIL',
        value: TranslateService.get('entities/user/withoutEmail'),
      },
    ].sort((a, b) => {
      return a.value.localeCompare(b.value);
    });
  }

  formatDateWithoutHour(): any[] {
    return [
      {
        id: 'yyyy/MM/dd',
        value: 'yyyy/MM/dd',
      },
      {
        id: 'dd/MM/yyyy',
        value: 'dd/MM/yyyy',
      },
    ].sort((a, b) => {
      return a.value.localeCompare(b.value);
    });
  }

  formatDateWithHour(): any[] {
    return [
      {
        id: 'yyyy/MM/dd HH:mm:ss',
        value: 'yyyy/MM/dd HH:mm:ss',
      },
      {
        id: 'dd/MM/yyyy HH:mm:ss',
        value: 'dd/MM/yyyy HH:mm:ss',
      },
    ].sort((a, b) => {
      return a.value.localeCompare(b.value);
    });
  }

  /** Obtient la liste des dynamicPropertyLocationEnum trié par position */
  dynamicPropertyLocationSort(): any[] {
    let sort = this.dynamicPropertyLocationEnum();

    let finalSort = [];
    finalSort.push(
      sort.find((d) => d.id == DynamicPropertyLocationEnum.Before),
    );
    finalSort.push(
      sort.find((d) => d.id == DynamicPropertyLocationEnum.Center),
    );
    finalSort.push(sort.find((d) => d.id == DynamicPropertyLocationEnum.After));
    finalSort.push(sort.find((d) => d.id == DynamicPropertyLocationEnum.Full));
    return finalSort;
  }

  limitedLdapObjectClassType() {
    return this.ldapObjectClassType().filter(
      (s) =>
        s.id == LdapObjectClassType.User ||
        s.id == LdapObjectClassType.Organization ||
        s.id == LdapObjectClassType.Asset,
    );
  }
  limitedLdapObjectClass() {
    return this.ldapObjectClassType().filter(
      (s) =>
        s.id == LdapObjectClassType.User ||
        s.id == LdapObjectClassType.Organization ||
        s.id == LdapObjectClassType.Role ||
        s.id == LdapObjectClassType.Asset,
    );
  }

  CustomFeatureType(): any[] {
    return [
      {
        id: 0,
        name: 'switchMobile',
      },
    ];
  }

  private formatToIdValuePair(id, value = null) {
    return {
      id: id,
      value: value != null ? value : id,
    };
  }

  availableTextSizes(): any[] {
    return [
      this.formatToIdValuePair('8pt'),
      this.formatToIdValuePair('10pt'),
      this.formatToIdValuePair('12pt'),
      this.formatToIdValuePair('14pt'),
      this.formatToIdValuePair('18pt'),
      this.formatToIdValuePair('24pt'),
      this.formatToIdValuePair('36pt'),
    ];
  }

  availableTextFonts() {
    return [
      this.formatToIdValuePair('Arial'),
      this.formatToIdValuePair('Courier New'),
      this.formatToIdValuePair('Georgia'),
      this.formatToIdValuePair('Impact'),
      this.formatToIdValuePair('Lucida Console'),
      this.formatToIdValuePair('Segoe UI'),
      this.formatToIdValuePair('Tahoma'),
      this.formatToIdValuePair('Times New Roman'),
      this.formatToIdValuePair('Verdana'),
    ];
  }
}
