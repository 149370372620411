<cl-work-page>
  <cl-work-page-header>
    <cl-work-page-header-content style="border-left: 0px !important">
      <cl-work-page-header-content-body>
        <cl-toolbar class="action">
          <cl-toolbar-items [items]="toolbar?.itemsChildren.toArray()">
            <cl-toolbar-item-dropdown-button
              *clcHasPolicies="policies + '.update'"
              [text]="'globals/import' | translate"
              icon="fas fa-paper-plane"
              keyExpr="id"
              displayExpr="name"
              [source]="importPopup.importSource"
              (onItemClick)="importPopup.import($event)"
              [visible]="mode == 0"
              [disabled]="canImport"
            ></cl-toolbar-item-dropdown-button>
            <cl-toolbar-item-dropdown-button
              *clcHasPolicies="policies + '.update'"
              [text]="'edit' | translate"
              icon="fas fa-pencil-alt"
              keyExpr="id"
              displayExpr="name"
              [splitButton]="true"
              [source]="newCommandSource"
              [disabled]="selectedKeys?.length === 0"
              (onItemClick)="openMassiveEditPopUp($event)"
              (onButtonClick)="editClick($event)"
              [visible]="mode == 0"
            ></cl-toolbar-item-dropdown-button>
            <cl-toolbar-item-button
              *clcHasPolicies="policies + '.update'"
              [text]="'delete' | translate"
              icon="fas fa-pencil-alt"
              [disabled]="selectedKeys?.length === 0"
              (onClick)="deleteAsset($event)"
            ></cl-toolbar-item-button>
            <cl-toolbar-item-button
              [text]="'restore' | translate"
              icon="fas fa-pencil-alt"
              [disabled]="selectedKeys?.length === 0"
              (onClick)="restore()"
              [visible]="mode == 1"
            ></cl-toolbar-item-button>
            <cl-toolbar-item-button
              [text]="'manage' | translate"
              [visible]="route !== undefined"
              (onClick)="onGoTo()"
              icon="fas fa-arrow-right"
              [visible]="mode == 0"
            >
            </cl-toolbar-item-button>
          </cl-toolbar-items>
        </cl-toolbar>
      </cl-work-page-header-content-body>
    </cl-work-page-header-content>
  </cl-work-page-header>
  <cl-work-page-content>
    <mat-tab-nav-panel #tabPanelStock></mat-tab-nav-panel>
    <nav
      mat-tab-nav-bar
      [tabPanel]="tabPanelStock"
      mat-stretch-tabs="false"
      class="tab-nav-bar"
    >
      <a
        mat-tab-link
        (click)="mode = StockMode.Current; current()"
        [active]="mode == StockMode.Current"
        >{{ 'globals/assets' | translate }}</a
      >
      <a
        mat-tab-link
        (click)="mode = StockMode.Deleted; deletedList()"
        [active]="mode == StockMode.Deleted"
        >{{ 'globals/trash' | translate }}</a
      >
    </nav>

    <clc-list
      class="list"
      [canSelect]="true"
      [source]="currentSource"
      [type]="type == undefined ? 'Grid' : type"
      [columns]="columns"
      [allowUpdate]="options.allowUpdating"
      [editMode]="options.mode"
      [multiple]="multiple"
      [keyExpr]="keyExpr"
      [parentIdExpr]="parentIdExpr"
      [recursive]="recursive"
      [(selectedKeys)]="selectedKeys"
      [(selectedData)]="selectedData"
      [layoutKey]="layoutKey"
      (onRowClick)="onRowClick($event)"
    >
    </clc-list>
  </cl-work-page-content>
</cl-work-page>

<clc-import-data-popup
  [service]="assetService"
  (onSuccess)="refresh()"
  #importPopup
>
</clc-import-data-popup>

<clc-massive-edit
  [title]="'entities/stock/massiveEdit' | translate"
  [rootForm]="source.rootState.form"
  [(isVisible)]="isMassiveEditVisible"
  [inputModel]="massiveEditModel"
  [(selectedKeys)]="selectedKeys"
  [inDefaultProperty]="inEditDefaultProperty"
  [service]="assetService"
></clc-massive-edit>

<clc-massive-edit
  [title]="'entities/stock/addWarranty' | translate"
  [(isVisible)]="isAddWarrantyVisible"
  [rootForm]="source.rootState.form"
  [inputModel]="addWarrantyModel"
  [(selectedKeys)]="selectedKeys"
  [service]="contractService"
  [updateFn]="addWarrantyCallback"
></clc-massive-edit>

<dx-load-panel
  shadingColor="rgba(0,0,0,0.1)"
  [visible]="loadWait"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="true"
></dx-load-panel>
